import React, { useState } from 'react';
import { Box } from '@mui/material';
import { DateRangePicker, Stack } from 'rsuite';
import './dateSelector.css';
import calendarSVG from './../calenderIcon/calendar.svg';
import CalendarIcon from '../calenderIcon/calendarIcon';

export default function DateRangeSelector({ value, setValue, width = '100%' }) {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',

        '& .rs-picker': {
          '& .rs-input-group': {
            background: 'transparent !important',
            border: '1px solid #AC9EC3 !important',
            outline: 'none',
            minWidth: '200px',
            width: width,
            height: '40px',
            position: 'relative',
            top: '-4px',
            '&:hover': {
              border: '1px solid #3F2F5B !important',
            },
            '&:focus , :focus-visible': {
              border: '1px solid #FF7676 !important',
            },
            '& input': {
              borderRadius: '100px',
              padding: '6.5px 14px',
              background: 'transparent !important',

              color: '#3F2F5B',
              fontSize: '14px',
              '&::placeholder': {
                color: '#3F2F5B !important',
                opacity: 1,
              },
            },
            '& .rs-input-group-addon': {
              padding: '0px 8px !important',
            },
          },
        },
      }}
    >
      <DateRangePicker
        value={value}
        onChange={setValue}
        showHeader={false}
        ranges={[]}
        defaultValue={[new Date().setMonth(-4), new Date()]}
        onClose={() => !open}
        caretAs={CalendarIcon}
        style={{
          borderRadius: '100px',
          height: '32px',
        }}
      />
    </Box>
  );
}
