import React from 'react';
import { Box } from '@mui/system';
import TrialComponent from '../../../components/trialcomponent/trialcomponent';

function TrialSection() {
  return (
    <Box
      sx={{
        padding: '1.2em 1em',
        background:
          'linear-gradient(180deg, rgba(255,96,48,1) 3%, rgba(255,93,42,1) 5%, rgba(255,94,41,1) 96%, rgba(255,96,48,1) 97%)',
      }}
    >
      <TrialComponent />
    </Box>
  );
}

export default TrialSection;
