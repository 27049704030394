import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Chip,
  IconButton,
  Badge,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

import { TENANT_URL } from '../../../config';

/** Custom Styled Accordion */
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: '4px',
  boxShadow: 'none',
  '&:before': { display: 'none' },
  '& .MuiAccordionSummary-root': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiAccordionSummary-root:last-child': { borderBottom: 'none' },
  '& .MuiChip-root': { backgroundColor: '#F0645B' },
  '& .MuiChip-label': { color: 'white' },
  '& .MuiBadge-badge': { color: 'white', backgroundColor: '#F0645B' },
  '& .MuiAccordionDetails-root':{padding: '5px 16px 16px'}
}));

const AdditionalMenuModal = ({ open, handleClose }) => {
  const [mealItems, setMealItems] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  console.log("neeeeewwwMeal", mealItems)

  const [additionalMenu, setAdditionalMenu] = useState([]);
  console.log('additionalMenu', additionalMenu);
  const [loading, setLoading] = useState(true);

  const handleUpdateAdditional = async() =>{
    const url = '/customer/app/additional-menu'
    const endpoint = `${TENANT_URL}${url}`; 
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.put(endpoint,{
        custom_menu: {...mealItems}
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("successfully updateddd",response)
      handleClose()
     
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }

  }

  const fetchDataSubscription = async (url, setter) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setter(response.data.data);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  const fetchDataMealsItem = async (url, setter) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("#####",response)
      setter(response.data);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      await fetchDataMealsItem('/customer/app/additional-menu', (data) => {
        // Populate mealItems state with fetched data (example structure assumed)
        setMealItems((prev) => ({
          ...prev,
          Monday: data?.monday?.items || data?.Monday?.items || [],
          Tuesday: data?.tuesday?.items || data?.Tuesday?.items || [],
          Wednesday: data?.wednesday?.items || data?.Wednesday?.items || [],
          Thursday: data?.thursday?.items || data?.Thursday?.items || [],
          Friday: data?.friday?.items || data?.Friday?.items || [],
          Saturday: data?.saturday?.items || data?.Saturday?.items || [],
          Sunday: data?.sunday?.items || data?.Sunday?.items || [],
        }));
      });

      await fetchDataSubscription('/customer/app/items/all', setAdditionalMenu);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleAddMeal = (day, meal) => {
    if (meal && !mealItems[day].includes(meal)) { // Check if the meal already exists
      setMealItems((prev) => ({
        ...prev,
        [day]: [...prev[day], meal],
      }));
    }
  };

  const handleDeleteMeal = (day, meal) => {
    setMealItems((prev) => ({
      ...prev,
      [day]: prev[day].filter((item) => item !== meal),
    }));
  };

  const getTotalItems = () =>
    Object.values(mealItems).reduce(
      (total, dayItems) => total + dayItems.length,
      0
    );

  const getAllItems = () =>
    Object.entries(mealItems).flatMap(([day, items]) =>
      items.map((item) => ({ day, item }))
    );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Additional Menu Items
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: 16, top: 4 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {/* Ongoing Items Accordion */}
            <StyledAccordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{gap:'20px', display:'flex', alignItems:'center'}}>
                  Ongoing Items{' '}
                  <Badge badgeContent={getTotalItems()} color="primary" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                  {getAllItems().map((entry, index) => {
                    const findMeal = additionalMenu.find(
                      (item) => item.id == entry.item
                    );
                    return (
                      <Chip
                        key={index}
                        label={`${entry?.day || ''}: ${findMeal?.name || ''}`}
                        color="primary"
                      />
                    );
                  })}
                </div>
              </AccordionDetails>
            </StyledAccordion>

            {/* Individual Days Accordion */}
            {Object.keys(mealItems).map((day, index) => (
              <StyledAccordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{gap:'15px', display:'flex', alignItems:'center'}}>
                    {day}{' '}
                    <Badge
                      badgeContent={mealItems[day].length}
                      color="primary"
                      style={{ marginLeft: 8 }}
                    />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                    {mealItems[day].map((meal, idx) => {
                      // console.log('meal', meal)
                      const findMeal = additionalMenu.find(
                        (item) => item.id == meal
                      );

                      return (
                        <Chip
                          key={idx}
                          label={findMeal?.name}
                          onDelete={() => handleDeleteMeal(day, meal)}
                          color="secondary"
                        />
                      );
                    })}
                  </div>
                  <Autocomplete
                    fullWidth
                    options={additionalMenu} // Use additionalMenu state as the source
                    getOptionLabel={(option) => option.name || ''} // Show item name
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Item and hit enter"
                        variant="outlined"
                        
                      />
                    )}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.target.value) {
                        const selectedOption = additionalMenu.find(
                          (item) => item.name === e.target.value
                        );
                        if (selectedOption) {
                          handleAddMeal(day, selectedOption.id); // Add the selected item by ID
                          e.target.value = ''; // Clear the input field
                        }
                      }
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleAddMeal(day, newValue.id); // Add the selected item by ID
                      }
                    }}
                    style={{ marginTop: 16 }}
                    sx={{
                      '.MuiInputBase-root':{
                        padding:'5px'
                      },
                      '.MuiButtonBase-root':{
                        height:'30px'
                      }
                    }}
                  />
                </AccordionDetails>
              </StyledAccordion>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{gap:'15px'}}>
        <Button
          onClick={() =>
            setMealItems({
              Monday: [],
              Tuesday: [],
              Wednesday: [],
              Thursday: [],
              Friday: [],
              Saturday: [],
              Sunday: [],
            })
          }
          color="secondary"
          style={{ border: '1px solid #F0645B', color: '#F0645B' }}
        >
          Reset
        </Button>
        <Button
          // onClick={handleClose}
          onClick={handleUpdateAdditional}
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#F0645B' }}
          
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdditionalMenuModal;
