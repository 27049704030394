import { Grid } from '@mui/material';
import React from 'react';
import Datacard from './cards/dataCard';
import { useProfile } from '../../../customHooks/useProfile';

const DataCards = () => {
  const { cardsData } = useProfile();
  const cardsDataTemplate = [
    {
      message: 'Orders',
      image: 'orders',
      carddata: cardsData?.order_count || '71',
    },
    {
      message: 'Subscription Plan',
      image: 'type',
      carddata: cardsData?.subscription_plan || 'Biweekly',
    },
    {
      message: '10 delivered / 16 remaining',
      image: 'name',
      carddata: cardsData?.plan_name || 'Current Plan',
    },
    {
      message: 'In Wallet',
      image: 'wallet',
      carddata: `${cardsData?.wallet_amount || '$5,092'}`,
    },
    {
      message: 'Messages Left',
      image: 'renewal',
      carddata: cardsData?.renewal_date?.split(' ')[0] || '100',
    },
    {
      message: 'Subscription Plan',
      image: 'messagesleft',
      carddata: cardsData?.remaining_meal
        ? cardsData.remaining_meal
        : 'Gold Plan',
    },
  ];
  return (
    <>
      <Grid container spacing={'15px'}>
        {cardsDataTemplate.map((card, index) => {
          return (
            <Grid item xs={12} sm={12} lg={6} xl={4}>
              <Datacard
                key={index}
                message={card.message}
                image={card.image}
                data={card.carddata}
                index={index}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default DataCards;
