import React from "react";
import Button from "@mui/material/Button";
import { bgcolor } from "@mui/system";
import './Button22.css';

const Button22 = ({ children, sx, backgroundColor = "transparent" }) => {
  return (
    <button className="btn_n"style={sx}>{children || 'Contact us'}</button>
  );
};

export default Button22;
