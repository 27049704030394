import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AdminLayout from '../../admin/layout/layout';
import AdminDashboard from '../../admin/pages/dashboard/dashboard';
import Login from '../../admin/pages/login/login';
import Signup from '../../admin/pages/signup/signup';
import Emails from '../../admin/pages/emails/emails';
import Messages from '../../admin/pages/messages/messages';
import Settings from '../../admin/pages/settings/settings';
import Reports from '../../admin/pages/reports/reports';
import TiffinServiceProviders from '../../admin/pages/tiffinServiceProviders/tiffinServiceProviders';
import Trail from '../../admin/pages/trail/index';
import ProtectedRoute from '../../admin/utilityComponents/protectedRoute/protectedRoute';
import NonLoginRoute from '../../admin/utilityComponents/nonLoginRoute/nonLoginRoute';
import SubscriptionPlans from '../../admin/pages/subscriptionPlans/subscriptionPlans';
import { TSPProvider } from '../../admin/customHooks/TSPContext';
import { SubscriptionPlansProvider } from '../../admin/customHooks/useSubscriptionPlans';
import ForgotPassword from '../../admin/pages/forgotPassword/forgotPassword';
import Profile from '../../admin/pages/profile/profile';
import Earnings from '../../admin/pages/earnings/earnings';
import { EarningsProvider } from '../../admin/customHooks/useEarnings';
import { SmsProvider } from '../../admin/customHooks/useSms';
import { EmailsProvider } from '../../admin/customHooks/useEmails';
import TenantProtectedRoute from '../../tenant/utilityComponents/protectedRoute/protectedRoute';
import { TenantAuthProvider, useTenantAuth } from '../../tenant/auth/auth';
import TenantNonLoginRoute from '../../tenant/utilityComponents/nonLoginRoute/nonLoginRoute';
import TenantLayout from '../../tenant/layout/layout';
import TenantLogin from '../../tenant/pages/login/login';
import TenantSignup from '../../tenant/pages/signup/signup';
import TenantDashboard from '../../tenant/pages/dashboard/dashboard';
import FoodCategory from '../../tenant/pages/foodCategory/foodCategory';
import { FoodCategorysProvider } from '../../tenant/customHooks/useFoodCategory';
import Marketing from '../../tenant/pages/marketing/marketing';
import { MarketingProvider } from '../../tenant/customHooks/useMarketing';
import TenantForgotPassword from '../../tenant/pages/forgotPassword/forgotPassword_';
import TenantCustomers from '../../tenant/pages/customers/customers';
import { CustomerProvider } from '../../tenant/customHooks/CustomerContext';
import { FoodItemsProvider } from '../../tenant/customHooks/useFoodItem';
import FoodItem from '../../tenant/pages/foodItem/foodItem';
import RouteSetting from '../../tenant/pages/routeSetting/routeSetting';
import WebSetting from '../../tenant/pages/webSetting/webSetting';
import { RouteProvider } from '../../tenant/customHooks/useRoute';
import { FoodMealsProvider } from '../../tenant/customHooks/useFoodMeal';
import FoodMeal from '../../tenant/pages/foodMeal/foodMeal';
import Riders from '../../tenant/pages/riders/riders';
import Roles from '../../tenant/pages/roles/roles';
import Users from '../../tenant/pages/users/users';
import { RolesProvider } from '../../tenant/customHooks/useRoles';
import { UsersProvider } from '../../tenant/customHooks/useUsers';
import CustomerProfile from '../../tenant/pages/profile/customerProfile';
import { RiderProvider } from '../../tenant/customHooks/useRider';
import DeliveryGroup from '../../tenant/pages/deliveryGroup/deliveryGroup';
import { DeliveryGroupProvider } from '../../tenant/customHooks/useDeliveryGroup';
import { PickupGroupProvider } from '../customHooks/usePickupGroup.jsx';
import Archive from '../../tenant/pages/archive/archive';
import { TenantSmsProvider } from '../../tenant/customHooks/useSms';
import TenantMessages from '../../tenant/pages/messages/messages';
import Campaign from '../../tenant/pages/campaign/campaign';
import Notification from '../../tenant/pages/notification/notification';
import CustomerNotification from '../../customer/pages/notification/Notification';
import { CampaignProvider } from '../../tenant/customHooks/useCampaign';
import { NotificationProvider } from '../../tenant/customHooks/useNotification';
import NewCampaign from '../../tenant/pages/campaign/newCampaign';
import Kitchen from '../../tenant/pages/kitchen/kitchen';
import { KitchenProvider } from '../../tenant/customHooks/useKitchen';
import { RestaurantProvider } from '../../tenant/customHooks/useRestaurants';
import AdOns from '../../tenant/pages/adOns/adons';
import { AdonsProvider } from '../../tenant/customHooks/useAdons';
import TenantErrorPage from '../../components/utils/TenantErrorPage';
import { StateProvider } from '../../tenant/pages/serviceLandingPage/api/stateContext';
import ServiceLandingPage from '../../tenant/pages/serviceLandingPage/index';
import CustomizationServiceLandingPage from '../../tenant/pages/serviceLandingPage/pages/CustomizationLandingPage/index';
import NewMenuPlan from '../../tenant/pages/foodMenuPlan/createMenu';
import MenuPlan from '../../tenant/pages/foodMenuPlan/index';
import { FoodMenuPlanProvider } from '../../tenant/customHooks/useMenuPlan';
import PlanCategory from '../../tenant/pages/planCategory/planCategory';
import CustomerProfile1 from '../../tenant/pages/CustomerProfile/index';
import { PlanCategoryProvider } from '../../tenant/customHooks/usePlanCategory';
import { DashboardProvider } from '../../tenant/customHooks/useDashboard';
import TenantPageNotFound from '../../components/utils/TenantPageNotFound';

import Subscription from '../../customer/pages/subscription/Subscription';
import OrderHistory from '../../customer/pages/orderhistory/OrderHistory';
import PaymentHistory from '../../customer/pages/paymenthistory/PaymentHistory';
import CustomerLayout from '../../customer/layout/layout';
import CustomerSectionProfile from '../../customer/pages/customersectionprofile/CustomerSectionProfile';
import MealPlus from '../../admin/pages/mealplus/index';

import '../../styles/fonts/newFonts.css';

import PickupGroup from '../../tenant/pages/pickupGroup/index';

const RoutesInfo = () => {
  const { loading, user } = useTenantAuth();
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  )?.tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData ?? [];
  function getIsActiveByModuleName(data, module_name) {
    for (const permission of data) {
      if (permission.module_name === module_name) {
        return permission.view;
      }
    }
    return false;
  }
  // useEffect(() => {
  // }, [permissionsData])

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <StateProvider>
              <ServiceLandingPage />
            </StateProvider>
          }
        />
        <Route path="/customerprofile" element={<CustomerProfile1 />} />
        <Route path="/error" element={<TenantErrorPage />} />
        <Route path="/pagenotfound" element={<TenantPageNotFound />} />
        <Route path="/superadmin">
          <Route element={<NonLoginRoute />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<AdminLayout />}>
              <Route index element={<AdminDashboard />} />
              <Route
                path="emails"
                element={
                  <EmailsProvider>
                    <Emails />
                  </EmailsProvider>
                }
              />
              <Route
                path="messages"
                element={
                  <SmsProvider>
                    <Messages />
                  </SmsProvider>
                }
              />
              <Route
                path="reports"
                element={
                  <TSPProvider>
                    <Reports />
                  </TSPProvider>
                }
              />
              <Route path="settings" element={<Settings />} />

              <Route
                path="earnings"
                element={
                  <EarningsProvider>
                    <SubscriptionPlansProvider>
                      <TSPProvider>
                        <Earnings />
                      </TSPProvider>
                    </SubscriptionPlansProvider>
                  </EarningsProvider>
                }
              />
              <Route
                path="subscription-plans"
                element={
                  <SubscriptionPlansProvider>
                    <SubscriptionPlans />
                  </SubscriptionPlansProvider>
                }
              />
              <Route
                path="service-providers"
                element={
                  <SubscriptionPlansProvider>
                    <TSPProvider>
                      <TiffinServiceProviders />
                    </TSPProvider>
                  </SubscriptionPlansProvider>
                }
              />

              <Route path="profile" element={<Profile />} />
            </Route>
          </Route>
        </Route>
        <Route path="/admin">
          <Route element={<TenantProtectedRoute />}>
            <Route element={<TenantLayout />}>
              <Route index element={ <DashboardProvider> <TenantDashboard /> </DashboardProvider>}/>
              <Route path="foodcategory"
                element={
                  tenant_permissions?.is_food_category_tab &&
                  genral_permissions?.is_food_category_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_food_category_tab'
                  ) ? (
                    <FoodCategorysProvider>
                      <FoodCategory />
                    </FoodCategorysProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="marketing"
                element={
                  tenant_permissions?.is_food_category_tab &&
                  genral_permissions?.is_food_category_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_food_category_tab'
                  ) ? (
                    <MarketingProvider>
                      <Marketing />
                    </MarketingProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />

              <Route path="fooditems"
                element={
                  tenant_permissions?.is_food_item_tab &&
                  genral_permissions?.is_food_item_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_food_item_tab'
                  ) ? (
                    <FoodItemsProvider>
                      <FoodItem />
                    </FoodItemsProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="foodmeal"
                element={
                  tenant_permissions?.is_meal_tab &&
                  genral_permissions?.is_meal_tab &&
                  getIsActiveByModuleName(user_permission, 'is_meal_tab') ? (
                    <FoodMealsProvider>
                      <FoodMeal />
                    </FoodMealsProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="adons"
                element={
                  tenant_permissions?.is_addon_tab &&
                  genral_permissions?.is_addon_tab &&
                  getIsActiveByModuleName(user_permission, 'is_addon_tab') ? (
                    <AdonsProvider>
                      <AdOns />
                    </AdonsProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="menuplan"
                element={
                  tenant_permissions?.is_menu_plan_tab &&
                  genral_permissions?.is_menu_plan_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_menu_plan_tab'
                  ) ? (
                    <FoodMenuPlanProvider>
                      <MenuPlan />
                    </FoodMenuPlanProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="newmenuplan"
                element={
                  tenant_permissions?.is_menu_plan_tab &&
                  genral_permissions?.is_menu_plan_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_menu_plan_tab'
                  ) ? (
                    <FoodMenuPlanProvider>
                      <NewMenuPlan />
                    </FoodMenuPlanProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="plancategory"
                element={
                  tenant_permissions?.is_plan_category_tab &&
                  genral_permissions?.is_plan_category_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_plan_category_tab'
                  ) ? (
                    <PlanCategoryProvider>
                      <PlanCategory />
                    </PlanCategoryProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="plancategory"
                element={
                  tenant_permissions?.is_plan_category_tab &&
                  genral_permissions?.is_plan_category_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_plan_category_tab'
                  ) ? (
                    <PlanCategory />
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="customers"
                element={
                  tenant_permissions?.is_customer_tab &&
                  genral_permissions?.is_customer_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_customer_tab'
                  ) ? (
                    <CustomerProvider>
                      <TenantCustomers />
                    </CustomerProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="routesetting"
                element={
                  tenant_permissions?.is_route_tab &&
                  genral_permissions?.is_route_tab &&
                  getIsActiveByModuleName(user_permission, 'is_route_tab') ? (
                    <RouteProvider>
                      <RouteSetting />
                    </RouteProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="deliverygroups"
                element={
                  tenant_permissions?.is_delivery_groups_tab &&
                  genral_permissions?.is_delivery_groups_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_delivery_groups_tab'
                  ) ? (
                    <DeliveryGroupProvider>
                      <DeliveryGroup />
                    </DeliveryGroupProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="pickupgroup"
                element={
                    <PickupGroupProvider>
                      <PickupGroup />
                    </PickupGroupProvider>
                }
              />
              <Route path="kitchen"
                element={
                  tenant_permissions?.is_kitchen_tab &&
                  genral_permissions?.is_kitchen_tab &&
                  getIsActiveByModuleName(user_permission, 'is_kitchen_tab') ? (
                    <KitchenProvider>
                      <Kitchen />
                    </KitchenProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="mealplus"
                // element={
                //   tenant_permissions?.is_mealplus_tab &&
                //   genral_permissions?.is_mealplus_tab &&
                //   getIsActiveByModuleName(
                //     user_permission,
                //     'is_mealplus_tab'
                //   ) ? (
                //     //   <KitchenProvider>
                //     //     <MealPlus />
                //     //   </KitchenProvider>
                //     <MealPlus />
                //   ) : (
                //     <Navigate to="/pagenotfound" />
                //   )
                // }
                element={<MealPlus />}
              />
              <Route path="trail" element={<Trail />} />

              <Route path="archive"
                element={
                  tenant_permissions?.is_customer_archive_tab &&
                  genral_permissions?.is_customer_archive_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_customer_archive_tab'
                  ) ? (
                    <FoodCategorysProvider>
                      <CustomerProvider>
                        <Archive />
                      </CustomerProvider>
                    </FoodCategorysProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="customers"
                element={
                  tenant_permissions?.is_customer_tab &&
                  genral_permissions?.is_customer_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_customer_tab'
                  ) ? (
                    <CustomerProvider>
                      <TenantCustomers />
                    </CustomerProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="routesetting"
                element={
                  tenant_permissions?.is_route_tab &&
                  genral_permissions?.is_route_tab &&
                  getIsActiveByModuleName(user_permission, 'is_route_tab') ? (
                    <RouteProvider>
                      <RouteSetting />
                    </RouteProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="websetting"
                element={
                  tenant_permissions?.is_web_setting_tab &&
                  genral_permissions?.is_web_setting_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_web_setting_tab'
                  ) ? (
                    <RestaurantProvider>
                      <RouteProvider>
                        <WebSetting />
                      </RouteProvider>
                    </RestaurantProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="riders"
                element={
                  tenant_permissions?.is_delivery_boy_tab &&
                  genral_permissions?.is_delivery_boy_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_delivery_boy_tab'
                  ) ? (
                    <RiderProvider>
                      <Riders />
                    </RiderProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="riders"
                element={
                  tenant_permissions?.is_delivery_boy_tab &&
                  genral_permissions?.is_delivery_boy_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_delivery_boy_tab'
                  ) ? (
                    <Riders />
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="profile" element={<CustomerProfile />} />
              <Route path="fooditems"
                element={
                  tenant_permissions?.is_food_item_tab &&
                  genral_permissions?.is_food_item_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_food_item_tab'
                  ) ? (
                    <FoodItemsProvider>
                      <FoodItem />
                    </FoodItemsProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="foodmeal"
                element={
                  tenant_permissions?.is_meal_tab &&
                  genral_permissions?.is_meal_tab &&
                  getIsActiveByModuleName(user_permission, 'is_meal_tab') ? (
                    <FoodMealsProvider>
                      <FoodMeal />
                    </FoodMealsProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="customers"
                element={
                  tenant_permissions?.is_customer_tab &&
                  genral_permissions?.is_customer_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_customer_tab'
                  ) ? (
                    <CustomerProvider>
                      <TenantCustomers />
                    </CustomerProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="roles"
                element={
                  tenant_permissions?.is_roles_tab &&
                  genral_permissions?.is_roles_tab &&
                  getIsActiveByModuleName(user_permission, 'is_roles_tab') ? (
                    <RolesProvider>
                      <Roles />
                    </RolesProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="users"
                element={
                  tenant_permissions?.is_users_tab &&
                  genral_permissions?.is_users_tab &&
                  getIsActiveByModuleName(user_permission, 'is_users_tab') ? (
                    <UsersProvider>
                      <Users />
                    </UsersProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="routesetting"
                element={
                  tenant_permissions?.is_route_tab &&
                  genral_permissions?.is_route_tab &&
                  getIsActiveByModuleName(user_permission, 'is_route_tab') ? (
                    <RouteProvider>
                      <RouteSetting />
                    </RouteProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="websetting"
                element={
                  tenant_permissions?.is_web_setting_tab &&
                  genral_permissions?.is_web_setting_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_web_setting_tab'
                  ) ? (
                    <WebSetting />
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="riders"
                element={
                  tenant_permissions?.is_delivery_boy_tab &&
                  genral_permissions?.is_delivery_boy_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_delivery_boy_tab'
                  ) ? (
                    <RiderProvider>
                      <Riders />
                    </RiderProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="archive"
                element={
                  tenant_permissions?.is_customer_archive_tab &&
                  genral_permissions?.is_customer_archive_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_customer_archive_tab'
                  ) ? (
                    <FoodCategorysProvider>
                      <CustomerProvider>
                        <Archive />
                      </CustomerProvider>
                    </FoodCategorysProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="messages"
                element={
                  tenant_permissions?.is_message_tab &&
                  genral_permissions?.is_message_tab &&
                  getIsActiveByModuleName(user_permission, 'is_message_tab') ? (
                    <TenantSmsProvider>
                      <TenantMessages />
                    </TenantSmsProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="campaign"
                element={
                  tenant_permissions?.is_food_campaign_tab &&
                  genral_permissions?.is_food_campaign_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_food_campaign_tab'
                  ) ? (
                    <CampaignProvider>
                      <Campaign />
                    </CampaignProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="notification"
                element={
                  tenant_permissions?.is_food_campaign_tab &&
                  genral_permissions?.is_food_campaign_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_food_campaign_tab'
                  ) ? (
                    <NotificationProvider>
                      <Notification />
                    </NotificationProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="newcampaign"
                element={
                  tenant_permissions?.is_food_campaign_tab &&
                  genral_permissions?.is_food_campaign_tab &&
                  getIsActiveByModuleName(
                    user_permission,
                    'is_food_campaign_tab'
                  ) ? (
                    <CampaignProvider>
                      <NewCampaign />
                    </CampaignProvider>
                  ) : (
                    <Navigate to="/pagenotfound" />
                  )
                }
              />
              <Route path="customizationServiceLandingPage"
                element={
                  <StateProvider>
                    <CustomizationServiceLandingPage />
                  </StateProvider>
                }
              />
            </Route>
          </Route>
          <Route element={<TenantNonLoginRoute />}>
            <Route path="login" element={<TenantLogin />} />
            <Route path="signup" element={<TenantSignup />} />
            <Route path="forgotpassword" element={<TenantForgotPassword />} />
            <Route
              path="websetting"
              element={
                tenant_permissions?.is_web_setting_tab &&
                genral_permissions?.is_web_setting_tab &&
                getIsActiveByModuleName(
                  user_permission,
                  'is_web_setting_tab'
                ) ? (
                  <WebSetting />
                ) : (
                  <Navigate to="/pagenotfound" />
                )
              }
            />
            <Route
              path="riders"
              element={
                tenant_permissions?.is_delivery_boy_tab &&
                genral_permissions?.is_delivery_boy_tab &&
                getIsActiveByModuleName(
                  user_permission,
                  'is_delivery_boy_tab'
                ) ? (
                  <Riders />
                ) : (
                  <Navigate to="/pagenotfound" />
                )
              }
            />
          </Route>
        </Route>
        <Route path="/customer">
          <Route element={<TenantProtectedRoute />}>
            <Route element={<CustomerLayout />}>
              <Route
                index
                element={
                  <DashboardProvider>
                    <TenantDashboard />
                  </DashboardProvider>
                }
              />
              <Route path="profile" element={<CustomerSectionProfile />} />
              <Route path="subscription" element={<Subscription />} />
              <Route path="notification" element={<CustomerNotification />} />
              <Route
                path="orderhistory"
                element={
                  <FoodCategorysProvider>
                    <OrderHistory />
                  </FoodCategorysProvider>
                }
              />
              <Route
                path="paymenthistory"
                element={
                  <FoodCategorysProvider>
                    <PaymentHistory />
                  </FoodCategorysProvider>
                }
              />
            </Route>
          </Route>
          <Route element={<TenantNonLoginRoute />}>
            <Route path="login" element={<TenantLogin />} />
            <Route path="signup" element={<TenantSignup />} />
            <Route path="forgotpassword" element={<TenantForgotPassword />} />
            <Route path="websetting" element={<WebSetting />} />
            <Route path="riders" element={<Riders />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default RoutesInfo;
