import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import FormModalInputSelectState from './inputs/formModalInputSelectState';
import FormModalInputSelectPlan from './inputs/formModalnputSelectPlan';
import FormModalInputText from './inputs/formModalInputText';
import MultiSelect from './inputs/formModalSelectMultiple';
import axios from 'axios';
import { useProfile } from '../../../../customHooks/useProfile';
import CustomButton from '../../../../components/customButton/CustomButton';
import SubscriptionData from './subscriptionData';
import Wallets from './wallets';
import { TENANT_URL } from '../../../../../config';
import FormModalInputSelectObject from './inputs/FormModalInputSelectObject';
import AdditionalMenuNew from '../../components/AdditionalMenuNew';

const InputForm = () => {
  const {
    getMenuPlanListByCategoryId,
    menuPlanList,
    setMenuPlanList,
    enableMenuPlan,
    setEnableMenuPlan,
    planCategories,
    subscriptionData,
    subsloading,
    planPrice,
    setPlanPrice,
    updatePlan,
    getMenuPlanData,
    getAdditionalMenuItems,
    getAdditionalItems,
  } = useProfile();
  const [categoryId, setCategoryId] = useState();
  // const [planPrice, setPlanPrice] = useState('0');
  const [newPlanCategoryName, setNewPlanCategoryName] = useState('');
  const [newSelectedMenuPlanId, setNewSelectedMenuPlanId] = useState();
  const [newMenuPlan, setNewMenuPlan] = useState('');
  const [mealMonthDetails, setMealMonthDetails] = useState({
    totalDaysInMonth: 30,
    totalMondayInMonth: 5,
    totalTuesdayInMonth: 4,
    totalWednesdayInMonth: 4,
    totalThursdayInMonth: 4,
    totalFridayInMonth: 4,
    totalSaturdayInMonth: 4,
    totalSundayInMonth: 5,
  });
  const [editAdditionalItemsModalOpen, setEditAdditionalItemsModalOpen] =
    useState(false);
  const handleClickAddNewAdditionalItems = () => {
    setEditAdditionalItemsModalOpen(true);
  };
  const initialMenuPlanData = {
    total: 0,
    delivered: 0,
    remaining: 0,
  };
  const [newMenuPlanData, setNewMenuPlanData] = useState(initialMenuPlanData);
  const [currentMenuPlanData, setCurrentMenuPlanData] =
    useState(initialMenuPlanData);

  useEffect(() => {
    if (newSelectedMenuPlanId) {
      getMenuPlanData(newSelectedMenuPlanId).then(({ data }) => {
        setNewMenuPlanData({
          total: data.new_total,
          delivered: data.new_delivered,
          remaining: data.new_remaining,
        });
      });
    }
  }, [newSelectedMenuPlanId]);

  useEffect(() => {
    getMenuPlanData().then(({ data }) => {
      setCurrentMenuPlanData({
        total: data.current_total,
        delivered: data.current_delivered,
        remaining: data.current_remaining,
      });
    });
  }, []);
  const SPGridHeaderButton = ({ children, onClick }) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          width: 'inherit',
          borderRadius: '25px',
          textTransform: 'capitalize',

          py: 1.4,
          fontWeight: 400,
          color: 'white',

          boxShadow: 'none',
          marginLeft: '10px',
        }}
        type="submit"
      >
        {children}
      </Button>
    );
  };

  let planSchema = {
    planCategory: Yup.string().required('*'),
    menuPlan: Yup.object().required('*'),
    subscriptionPlan: Yup.string().required('*'),
    deliverySlot: Yup.string().required('*'),
    payment: Yup.string(),
  };

  // if (planPrice > subscriptionData?.payment) {
  //   planSchema.payment = Yup.string().required('*');
  // }

  const validationSchema = Yup.object().shape(planSchema);

  useEffect(() => {
    if (categoryId) {
      getMenuPlanListByCategoryId(categoryId);
      setNewMenuPlanData(initialMenuPlanData);
    }
  }, [categoryId]);

  const diliverySlotList = [
    { id: 'Breakfast', name: 'Breakfast' },
    { id: 'Lunch', name: 'Lunch' },
    { id: 'Dinner', name: 'Dinner' },
  ];

  const subsList = [{ id: 'monthly', name: 'Monthly' }];
  const [currentMenuItemsData, setCurrentMenuItemsData] = useState({});
  const [customMenu, setCustomMenu] = useState({
    everyday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });
  const transformCustomMenu = (menu) => {
    const result = {};

    // Predefined order of days
    const days = [
      'everyday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    // Iterate through the days array to maintain the correct order
    days.forEach((day) => {
      // If the day exists in the menu, use its items; otherwise, set it to an empty array
      if (menu[day] && menu[day].items) {
        result[day] = menu[day].items;
      } else {
        result[day] = [];
      }
    });

    return result;
  };
  useEffect(() => {
    if (subscriptionData) {
      setPlanPrice(subscriptionData.plan_amount);
      setMealMonthDetails(subscriptionData.MealMonthDetails);
      setNewPlanCategoryName(subscriptionData.plan_category_name);
      // setCategoryId( subscriptionData.plan_category_id)
      setNewMenuPlan(subscriptionData.menu_plan_name);
      // setNewSelectedMenuPlanId(subscriptionData.menu_plan_id);
    }
  }, [subscriptionData]);

  useEffect(() => {
    getAdditionalMenuItems().then(({ data }) => {
      setCurrentMenuItemsData(data.data);
    });
    getAdditionalItems().then(({ data }) => {
      const result = transformCustomMenu(data);
      setCustomMenu(result);
    });
  }, []);
  const dayCount = (day) => {
    switch (day) {
      case 'everyday':
        return mealMonthDetails.totalDaysInMonth;
        break;
      case 'monday':
        return mealMonthDetails.totalMondayInMonth;
        break;
      case 'tuesday':
        return mealMonthDetails.totalTuesdayInMonth;
        break;
      case 'wednesday':
        return mealMonthDetails.totalWednesdayInMonth;
        break;
      case 'thursday':
        return mealMonthDetails.totalThursdayInMonth;
        break;
      case 'friday':
        return mealMonthDetails.totalFridayInMonth;
        break;
      case 'saturday':
        return mealMonthDetails.totalSaturdayInMonth;
        break;
      case 'sunday':
        return mealMonthDetails.totalSundayInMonth;
        break;
      default:
        console.log('Invalid day');
        return 1;
    }
  };
  // Function to update price from the customMenu
  const updatePrice = (price) => {
    const selectedIds = [];
    Object.keys(customMenu).forEach((day) => {
      const days = dayCount(day);
      for (let i = 0; i < days; i++) {
        selectedIds.push(...customMenu[day]);
      }
    });
    const totalPrice = selectedIds.reduce((sum, item) => {
      const price = currentMenuItemsData.find((i) => i.id == item).price || 0;
      return sum + (price || 0); // Add the price of each selected item
    }, 0);
    setPlanPrice(Number(totalPrice.toFixed(2)) + Number(price));
    return;
  };
  const handleSubmit = (values, actions) => {
    updatePlan(
      values.planCategory,
      values.deliverySlot,
      values.subscriptionPlan,
      values.menuPlan.id,
      values.payment
    );
  };

  const handleClose = () => {
    setEditAdditionalItemsModalOpen(!open);
  };

  return (
    <>
      <AdditionalMenuNew
        open={editAdditionalItemsModalOpen}
        setOpen={setEditAdditionalItemsModalOpen}
        handleClose={handleClose}
      />
      {subsloading ? (
        <></>
      ) : (
        <Box>
          <Formik
            initialValues={{
              deliverySlot: subscriptionData
                ? subscriptionData.delivery_time_slot
                : '',
              subscriptionPlan: subscriptionData
                ? subscriptionData.subscription_plan
                : '',
              planCategory: subscriptionData
                ? subscriptionData.plan_category_id
                : '',
              menuPlan: subscriptionData ? subscriptionData.menu_plan_name : '',
              payment: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnMount
          >
            {(props, index) => {
              const {
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                values,
                errors,
              } = props;

              console.log(values, errors);

              return (
                <Form onSubmit={handleSubmit}>
                  <Box>
                    <Grid container spacing={'30px'}>
                      <Grid item lg={4} xs={12}>
                        <Grid container spacing={'12px'}>
                          <Grid item xs={12} sm={12}>
                            <Field
                              name="planCategory"
                              list={planCategories}
                              label="Plan Category"
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                const selectedItem = planCategories.find(
                                  (item) => item.id === selectedValue
                                );
                                const selectedName = selectedItem
                                  ? selectedItem.name
                                  : '';

                                props.setFieldValue(
                                  'planCategory',
                                  e.target.value
                                );
                                setCategoryId(e.target.value);
                                setNewPlanCategoryName(selectedName);
                                setNewSelectedMenuPlanId('');
                                setNewMenuPlan('');
                                props.setFieldValue('menuPlan', '');
                                if (
                                  props.values.menuPlan &&
                                  props.values.menuPlan.planCategory ==
                                    e.target.value
                                ) {
                                  updatePrice(props.values.menuPlan.plan_price);
                                  setNewMenuPlan(props.values.menuPlan.name);
                                  props.setFieldValue(
                                    'menuPlan',
                                    props.values.menuPlan.name
                                  );
                                } else {
                                  setNewSelectedMenuPlanId('');
                                  setNewMenuPlan('');
                                  props.setFieldValue('menuPlan', '');
                                }

                                setEnableMenuPlan(true);
                              }}
                              onBlur={handleBlur}
                              component={FormModalInputSelectState}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '5px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'Outfit',
                                  fontWeight: 500, // The last declared font-weight is 500
                                  fontSize: '1rem',
                                  lineHeight: 1.5,
                                  letterSpacing: '0.00938em',
                                  color: '#3F2F5B',
                                  margin: '0',
                                }}
                              >
                                Menu Plan
                              </p>
                              <a
                                style={{
                                  textDecoration: 'underline',
                                  color: '#FF6B00',
                                  cursor: 'pointer',
                                  fontFamily: 'Outfit',
                                }}
                                onClick={handleClickAddNewAdditionalItems}
                              >
                                Additional items
                              </a>
                            </label>

                            <Field
                              disabled={!enableMenuPlan}
                              name="menuPlan"
                              list={menuPlanList}
                              // label="Menu Plan"
                              onChange={(e) => {
                                props.setFieldValue('menuPlan', e.target.value);
                                updatePrice(e.target.value.plan_price);
                                setNewPlanCategoryName(
                                  e.target.value.plan_category
                                );
                                setNewMenuPlan(e.target.value.name);
                                setNewSelectedMenuPlanId(e.target.value.id);
                              }}
                              onBlur={handleBlur}
                              subscriptionData={subscriptionData}
                              value={newMenuPlan}
                              component={FormModalInputSelectObject}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Field
                              name="deliverySlot"
                              list={diliverySlotList}
                              label="Delivery Slot"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={FormModalInputSelectState}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Field
                              name="subscriptionPlan"
                              list={subsList}
                              label="Subscription Plan"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={FormModalInputSelectState}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={8}
                        xs={12}
                        sx={{
                          marginTop: '30px',
                        }}
                      >
                        <Grid container spacing={'30px'}>
                          <Grid item lg={12} xl={6}>
                            <SubscriptionData
                              planCategoryName={
                                subscriptionData.plan_category_name
                              }
                              type={'Running Plan'}
                              menuPlan={subscriptionData.menu_plan_name}
                              planData={currentMenuPlanData}
                            />
                          </Grid>
                          <Grid item lg={12} xl={6}>
                            <SubscriptionData
                              planCategoryName={newPlanCategoryName}
                              type={'New Plan'}
                              menuPlan={newMenuPlan}
                              planData={newMenuPlanData}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Wallets
                              wallet={subscriptionData.payment}
                              due={
                                planPrice > subscriptionData.payment
                                  ? (
                                      planPrice - subscriptionData.payment
                                    ).toFixed(2)
                                  : 0
                              }
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                                gap: '5px',
                                height: '100%',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '125px',
                                  flex: 1,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    marginBottom: '8px',
                                  }}
                                >
                                  Plan Price
                                </Typography>
                                <Typography
                                  sx={{
                                    height: '45px',
                                    bgcolor: '#FF6B00',
                                    color: '#FFFFFF',
                                    fontWeight: '500',
                                    borderRadius: '10px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  ${planPrice}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  flex: 1,
                                }}
                              >
                                <Field
                                  name="payment"
                                  label="Payment Amount"
                                  placeholder="100$"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  component={FormModalInputText}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{
                              display: 'flex',
                              alignItems: 'end',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <SPGridHeaderButton
                              variant="filled"
                              text={'Update'}
                              type={'submit'}
                            >
                              Update
                            </SPGridHeaderButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}{' '}
    </>
  );
};

export default InputForm;
