import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as CustomersIcon } from '../../../../../icons/mealicon.svg';
import { ReactComponent as CustomersIcon1 } from '../../../../../icons/mealwhiteicon.svg';

const commonBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: { md: '10px', lg: '15px', sm: '15px' },
  borderRadius: '15px',
  border: '1px solid rgb(244, 241, 248)',
  background:
    'var(--Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
  backdropFilter: 'blur(20px)',
  width: { md: '250px', lg: '270px', xl: '300px', xs: '100%' },
};

const iconBoxStyles = {
  width: '63px',
  height: '63px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '63px',
};
const Cards = ({rows}) => {
  console.log("rows:",rows);
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 33, lg: '280px', xl: 40 }}>
        <Grid item xs={12} sm={4} md={2}>
          <Box sx={commonBoxStyles}>
            <Box sx={{ ...iconBoxStyles, background: '#ffffff' }}>
              <CustomersIcon style={{ width: '35px', height: '35px' }} />
            </Box>
            <Box sx={{ ml: '10px' }}>
              <Typography sx={{ fontSize: '22px', fontWeight: '500' }}>
                {rows.length}
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                Total Trial Meals
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Box sx={commonBoxStyles}>
            <Box sx={{ ...iconBoxStyles, background: '#29A61D' }}>
              <CustomersIcon1 style={{ width: '35px', height: '35px' }} />
            </Box>
            <Box sx={{ ml: '10px' }}>
              <Typography sx={{ fontSize: '22px', fontWeight: '500' }}>
              {rows.length}
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                Active Trial Meals
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Box sx={commonBoxStyles}>
            <Box sx={{ ...iconBoxStyles, background: '#F6600C' }}>
              <CustomersIcon1 style={{ width: '35px', height: '35px' }} />
            </Box>
            <Box sx={{ ml: '10px' }}>
              <Typography sx={{ fontSize: '22px', fontWeight: '500' }}>
                0
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                Inactive Trial Meals
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Cards;
