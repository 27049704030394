import { IconButton, Typography } from '@mui/material';

const NavbarItem = ({ src, number, bg,onClick  }) => {
  const numberStyles = {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: bg,
    fontSize: '10px',
    borderRadius: '50%',
    color: 'white',
  };

  return (
    <IconButton onClick={onClick}>
      {number > 0 &&
        (number < 10 ? (
          <Typography sx={{ ...numberStyles, px: 0.7 }}>{number}</Typography>
        ) : (
          <Typography sx={{ ...numberStyles, px: 0.3 }}>9+</Typography>
        ))}
      <img src={src} alt="icon" style={{ width: '15  px' }} />
    </IconButton>
  );
};

export default NavbarItem;
