import { Box } from '@mui/material';
import darkModeIcon from '../../../../icons/darkmode.svg';
import settingsIcon from '../../../../icons/settings.svg';
import emailIcon from '../../../../icons/email.svg';
import bellIcon from '../../../../icons/bell.svg';
import notificationIcon from '../../../pages/customersectionprofile/assets/notification.svg';
import NavbarItem from '../navbarItem/navbarItem';
import { Navigate, useNavigate } from 'react-router-dom';

const NavbarItems = ({setShowHoverSidebar}) => {
  const navigate = useNavigate()
  const handleNotify = ()  => {
    setShowHoverSidebar(false)
    console.log("hellllllllll")
   navigate('/customer/notification/');
  }
  return (
    <Box>
      {/* <NavbarItem src={darkModeIcon} /> */}
      {/* <NavbarItem src={settingsIcon} /> */}
      {/* <NavbarItem src={emailIcon} number={17} bg={'green'} />
      <NavbarItem src={bellIcon} /> */}
      <NavbarItem src={notificationIcon} onClick={handleNotify}/>
    </Box>
  );
};

export default NavbarItems;
