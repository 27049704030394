import React, { useEffect } from 'react';
import { Box, InputLabel } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import FormModalInputText from './formModalInputText';
import { useFoodMeals } from '../../../../../../customHooks/useFoodMeal';
import Tooltip from '@mui/material/Tooltip';
const DAYS = [
  {
    key: 'Sunday',
    label: 'S',
    name: 'is_sunday',
  },
  {
    key: 'Monday',
    label: 'M',
    name: 'is_monday',
  },
  {
    key: 'Tuesday',
    label: 'T',
    name: 'is_tuesday',
  },
  {
    key: 'Wednesday',
    label: 'W',
    name: 'is_wednesday',
  },
  {
    key: 'Thursday',
    label: 'T',
    name: 'is_thursday',
  },
  {
    key: 'Friday',
    label: 'F',
    name: 'is_friday',
  },
  {
    key: 'Saturday',
    label: 'S',
    name: 'is_saturday',
  },
];

const ToggleDays = ({
  label,
  passedRef,
  field,
  form,
  days,
  setDays,
  dailypriceref,
  inputHandleChange,
  inputHandleBlur,
  mealAmount,
  values,
  ...props
}) => {
  const { restaurentDaysOff } = useFoodMeals();

  useEffect(() => {
    if (mealAmount > 0) {
      values.dailyprice = mealAmount;
    }
  }, [mealAmount]);
  const toggleDay = (index) => {
    if (days.includes(index)) {
      return days.filter((day) => day !== index);
    }
    return [...days, index];
  };
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (mealAmount) {
      setFieldValue('dailyprice', mealAmount, false);
    }
  }, [mealAmount]);
  return (
    <Box
      sx={{
        backgroundColor: '#F4F4F4',
        display: 'flex',
        width: '100%',
        marginTop: '10px',
        padding: '15px',
        borderRadius: '15px',
      }}
    >
      <Box
        sx={{
          flex: 1.5,
          width: '100%',
          padding: '4px',
        }}
      >
        <InputLabel htmlFor="my-input" sx={{ mb: 1, fontWeight: 'bold' }}>
          {label}
        </InputLabel>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          {DAYS.map((day, index) => {
            var isDayOff = restaurentDaysOff.includes(index);

            return (
              <>
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#FF7676',
                      },
                    },
                    arrow: {
                      sx: {
                        color: '#FF7676',
                      },
                    },
                  }}
                  title={day.key}
                  placement="top"
                  arrow
                >
                  <button
                    key={day.key}
                    type="button"
                    style={{
                      color: days.includes(index) ? 'white' : '#3f2f5b',
                      backgroundColor: days.includes(index)
                        ? '#239B17'
                        : 'white',
                      border: '1px solid transparent',
                      borderRadius: '50%',
                      minWidth: '40px',
                      maxWidth: '40px',
                      height: '40px',
                      fontFamily: 'Outfit',
                      fontSize: '16px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      letterSpacing: '0px',
                      textAlign: 'center',
                      textTransform: 'unset',
                      cursor: isDayOff ? 'not-allowed' : 'pointer',
                      pointerEvents: isDayOff ? 'none' : 'inherit',
                      opacity: isDayOff ? '.5' : '1',
                      padding: 0,
                    }}
                    onClick={(e) => {
                      const updatedDays = toggleDay(index);
                      setDays(updatedDays);
                      passedRef.current = updatedDays;
                      form.setFieldValue(field.name, updatedDays);

                      const dayName = DAYS[index].name;
                      form.setFieldValue(dayName, updatedDays.includes(index));
                    }}
                  >
                    {day.label}
                  </button>
                </Tooltip>
                <input
                  type="hidden"
                  name={day.name}
                  value={days.includes(index)}
                />
              </>
            );
          })}
        </Box>
        <InputLabel htmlFor="my-input-2" sx={{ mt: 1, fontWeight: '500' }}>
          {days.length} Days {label}
        </InputLabel>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignContent: 'center',
          justifyContent: 'center',
          paddingLeft: '30px',
          paddingRight: '15px',
          WebkitFlex: '1',
          MsFlex: '1',
          flex: '1',
          borderLeft: '1px solid #C7C7C7',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#F4F4F4',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '18px',
            color: 'white',
            p: 2,
            fontSize: '18px',
            textAlign: 'center',
            fontSize: '18px',
            padding: '5px',
          }}
        >
          <Field
            InputProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="dailyprice"
            label="Meal amount"
            placeholder="Meal amount"
            passedRef={dailypriceref}
            onChange={inputHandleChange}
            onBlur={inputHandleBlur}
            component={FormModalInputText}
            value={form.values.dailyprice}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ToggleDays;
