// import React from 'react'
// import CustomizationServiceLandingPage from './pages/CustomizationLandingPage/components/CustomizationServiceLandingPagePreview';
// import { Box } from '@mui/material';

// function index() {
//   return (
//     <Box sx={{height:'100vh',overflow: 'auto'}}>
//       <CustomizationServiceLandingPage />
//     </Box>
//   )
// }

// export default index


import React, { useState, useEffect } from 'react';
import CustomizationServiceLandingPage from './pages/CustomizationLandingPage/components/CustomizationServiceLandingPagePreview';
import { Box, CircularProgress, Container } from '@mui/material';

function Index() {
  // Loading state
  const [loading, setLoading] = useState(true);

  // Simulate data loading or some async action
  useEffect(() => {
    // Simulating async data load with setTimeout
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds
    }, 3000);

    // Cleanup timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={{ height: '100vh', overflow: 'auto' }}>
      {loading ? (
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress /> {/* The loader */}
        </Container>
      ) : (
        <CustomizationServiceLandingPage /> // Your main content
      )}
    </Box>
  );
}

export default Index;
