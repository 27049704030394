import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormikContext } from 'formik';

const StyledSelect = styled(Select)({
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',

    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function WebInputSelectCountry({
  label,
  list,
  passedRef,
  setCurrentCountry,
  currentVal,
  field,
  styleCustom,
  form,
  ...props
}) {
  const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
    useFormikContext();
  return (
    <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
      <Typography
        style={{
          fontWeight: '500',
          marginBottom: '7px',
        }}
      >
        {label}
      </Typography>
      <StyledSelect
        sx={{ backgroundColor: 'white', borderRadius: '10px', ...styleCustom }}
        onChange={(e) => {
          setCurrentCountry(e.target.value);
          setFieldValue(field.name, e.target.value, false);
        }}
        value={field.value}
      >
        <MenuItem key="null" value={''} sx={{ display: 'none' }} />
        {list.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}

export default WebInputSelectCountry;
