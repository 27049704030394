import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  Paper,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { TENANT_URL } from "../../../config";
import { useOutletContext } from "react-router-dom";

// Custom Styled Components
const CustomPaper = styled(Paper)(({ theme }) => ({
  borderRadius: "10px",
  boxShadow: "0px 14px 42px 0px #080F340F",
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
  },
  "& .Mui-selected": {
    backgroundColor: "#F0645B !important", // Active page background color
    color: "#FFFFFF", // Active page text color
    fontWeight: "bold", // Optional: Make it bold
  },

}));

const StickyTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: "500px", // Set max height for scrollability
  overflowY: "auto",
  height:'500px',
   // Enable vertical scrolling
  "& thead th": {
    position: "sticky", // Sticky header
    top: 0,
    zIndex: 2, // Ensure it stays above the rows
    backgroundColor: theme.palette.background.paper, // Match background color
    boxShadow: "0px 2px 2px -1px rgba(0,0,0,0.2)", // Optional: subtle shadow
  },

}));

const PaymentHistory = () => {
  const { searchVal } = useOutletContext();
  console.log('@@@searchVal', searchVal)
  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState({})
  const totalPages = Math.ceil(currPage?.total / currPage?.per_page);


  const handlePagination = (event,page) =>{
    console.log("pagggg",page)
    fetchDataSubscription(`/customer/app/transaction/history?page=${page}`)

  }

  const fetchDataSubscription = async (url) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = "174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e";
    try {
      const response = await axios.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(response.data.data || []); // Default to empty array if data is null/undefined
      setCurrPage(response?.data?.meta)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataSubscription("/customer/app/transaction/history");
  }, []);

  useEffect(() => {
    const getData = setTimeout(async() => {
      const url = `/customer/app/transaction/history?search=${searchVal}`
      const endpoint = `${TENANT_URL}${url}`;
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(response.data.data || []); // Set empty array if no data
      setCurrPage(response)
    } catch (error) {
      console.error('Error fetching while in search ', error);
    }

    }, 2000)

    return () => clearTimeout(getData)
  }, [searchVal])

  // Ensure at least 10 rows, filling with placeholders if needed
  const rows = [...data];

  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "1200px",
        margin: "0 auto",
        // height: "120vh",
      }}
    >
      <CustomPaper>
        <Box>
          <StickyTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="500" fontSize={13} textAlign={"start"}>
                      Sr.#
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="500" fontSize={13} textAlign={"center"}>
                      Payment Method
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="500" fontSize={13} textAlign={"center"}>
                      Payment Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="500" fontSize={13} textAlign={"center"}>
                      Payment Amount
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
  {rows.length > 0 ? (
    rows.map((row, index) => (
      <TableRow key={row.id || index}>
        <TableCell
          sx={{ fontSize: "13px", fontWeight: "400", textAlign: "start" }}
        >
          {index + 1}
        </TableCell>
        <TableCell
          sx={{ fontSize: "13px", fontWeight: "400", textAlign: "center" }}
        >
          {row.payment_type}
        </TableCell>
        <TableCell
          sx={{ fontSize: "13px", fontWeight: "400", textAlign: "center" }}
        >
          {row.date}
        </TableCell>
        <TableCell
          sx={{ fontSize: "13px", fontWeight: "400", textAlign: "center" }}
        >
          {row.amount}
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={4} align="center" sx={{border:'none',height:'440px'}}>
        <Typography variant="body1" color="textSecondary" >
          No data found
        </Typography>
      </TableCell>
    </TableRow>
  )}
</TableBody>

            </Table>
          </StickyTableContainer>
        </Box>
      </CustomPaper>
      <Box display="flex" justifyContent="end" marginTop="16px" padding="16px">
        <CustomPagination count={totalPages || 1} variant="outlined" shape="rounded" sx={{justifyContent:'end'}} onChange={handlePagination}/>
      </Box>
    </Box>
  );
};

export default PaymentHistory;
