import {
  Box,
  Button,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from './useUtility';
import DeliverySearchBy from './component/deliverySearchBy';
import RouteSeacrhBy from './component/routeSearchBy';
import DateRangeSelector from '../../../admin/pages/reports/components/modals/dateSelector';
import { useEffect, useRef, useState } from 'react';
import ScooterIcon from './icons/scooter.svg';
import MapIcon from './icons/map.svg';
import PrintIcon from './icons/print.svg';
import RouteModal from './component/groupRoute/routeModal';
import { usePickupGroup } from '../../customHooks/usePickupGroup';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { TENANT_URL } from '../../../config';
import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import SearchTextField from '../../components/SearchTextField';
import PickupFilter from '../../components/filter/PickupFilter';
import PrintLabelIcon from '@mui/icons-material/Print';
export const CustomTextField = styled(TextField)`
  fieldset {
    width: 100%;
  }
`;
const headerStyles = {
  fillColor: 'white', // White background color for headers
  textColor: 'black', // Black text color for headers
  fontStyle: 'bold', // Bold font style for headers
};

const cellStyles = {
  fillColor: 'white', // White background color for table cells
  textColor: 'black', // Black text color for table cells
};

const borderStyles = {
  lineWidth: 0.1, // Border line width
  lineColor: [0, 0, 0], // Black border color
};
const SPGridHeaderButton = ({ children, onClick, vari, icon }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        width: !isMdUp ? '100%' : '140px',
      }}
    >
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,
          width: '100%',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          py: '1px',
          px: '{ md: 3, lg: 3, sm: 2, xs: 1 }',
          textTransform: 'capitalize',
          padding: '2px 0px 2px 0px',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const SPGridHeaderDatePicker = ({ children, onClick, vari, icon }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        width: !isMdUp ? '100%' : '260px',
      }}
    >
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          width: '100%',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};
const SPGridHeaderButton2 = ({ children, onClick }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,

          width: !isMdUp ? '100%' : 'auto',
          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 5, lg: 5, sm: 2, xs: 1 },
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
          marginRight: '10px',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const SPGridHeaderButtonIcon = ({
  children,
  onClick,
  vari,
  icon,
  deliveryGroupList,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const anchorRef = useRef(null);
  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          my: !isMdUp ? 0.5 : 0,
          width: !isMdUp ? '100%' : '10%',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const PickupGridHeader = ({
  keyword,
  setKeyword,
  searchBy,
  setSearchBy,
  onClickFunc,
  list,
  route,
  setRoute,
  dateRange,
  setDateRange,
  startDate,
  endDate,
  dateonClick,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { datemodalOpen, setDateModalopen } = useUtility();
  const [deliveryGroupList, setDeliveryGroupList] = useState([]);

  const { routes } = usePickupGroup();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  function formatDate(date) {
    return dayjs(date).format('YYYY-MM-DD');
  }
  function formatDateReport(date) {
    return dayjs(date).format('YYYY/MM/DD');
  }
  const handleClose = () => {
    setOpen(false);
  };
  const getDeliveryGroupLists = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/all/daily/pickup`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        name: keyword,
        is_active: true,
        start_date: formatDate(dateRange[0]),
        end_date: formatDate(dateRange[1]),
        route_name: route,
      },
    };

    try {
      const response = await axios(getConfig);
      setDeliveryGroupList(response.data.data);
      handleButtonClick();
    } catch (error) {
      console.error(error);
    }
  };
  const generatePDF = async () => {
    console.log(dateRange);
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      const getConfig = {
        method: 'get',
        url: `${TENANT_URL}/all/daily/delivery`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          name: keyword,
          is_active: true,
          start_date: formatDate(dateRange[0]),
          end_date: formatDate(dateRange[1]),
          route_name: route,
        },
      };

      const response = await axios(getConfig);
      const deliveryGroupList = response.data.data;
      const doc = new jsPDF({
        orientation: 'landscape', // Set orientation to landscape for wider page
        unit: 'mm', // Set measurement unit to millimeters
        format: [297, 210], // Set custom width and height for the page (297mm x 210mm is A4 size landscape)
      });
      doc.text(
        `Route Name : ${[
          ...new Set(deliveryGroupList.map((item) => item.route_name)),
        ]}`,
        20,
        10
      ); // Adjust the position as needed
      doc.text(
        `Date: ${formatDateReport(dateRange[0])} - ${formatDateReport(
          dateRange[1]
        )}`,
        20,
        20
      ); // Adjust the position as needed
      autoTable(doc, {
        startY: 30, // Move the table down

        head: [['Route Id', 'Name', 'Address', 'Tel', 'Delivery Note', '']],
        body: deliveryGroupList.map((item) => [
          item.id,
          item.name,
          item.address,
          item.phone,
          item.driver_instruction,
          '',
        ]),
        theme: 'grid',
        tableWidth: 260, // Adjust table width accordingly
        headStyles: headerStyles,
        bodyStyles: cellStyles,
        footStyles: {}, // You can define styles for footers if needed
        alternateRowStyles: {}, // Styles for alternate rows if needed
        styles: borderStyles,
        columnStyles: {},
      });

      doc.save('Delivery Group Report');
    } catch (error) {
      console.error(error);
    }
  };

  const anchorRef = useRef(null);

  const handleButtonClick = () => {
    anchorRef.current.click();
  };
  return (
    <Box
      sx={{
        width: '100%',
        px: '10.4px',
        paddingBottom: '10px',
      }}
    >
      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          width: '100%',
          justifyContent: 'space-between',
          // flexWrap: 'wrap',
          // alignItems: 'center',
          // gap: '15px',
        }}
      >
        <Box
          sx={{
            display: isMdUp ? 'flex' : 'block',
            width: '100%',
            // justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <Box>
            <SearchTextField
              keyword={keyword}
              setKeyword={setKeyword}
              width="240px"
            />
          </Box>
          {/* <Box>
            <RouteSeacrhBy
              isMdUp={isMdUp}
              route={route}
              setRoute={setRoute}
              list={list}
              width="240px"
            />
          </Box> */}
          <Box>
            <DateRangeSelector
              value={dateRange}
              setValue={setDateRange}
              width={isMdDown ? '100%' : '240px'}
            />
          </Box>
          <Box>
            <PickupFilter
              keyword={keyword}
              setKeyword={setKeyword}
              width={isMdDown ? '100%' : '180px'}
            />
          </Box>
          <Box
            sx={{
              marginLeft: 'auto',
            }}
          >
            <Tooltip title={'Label Print'} placement="top" arrow>
              <Button
                sx={{
                  backgroundColor: 'transparent',
                  borderRadius: '20px',
                  color: '#3F2F5B',
                  textTransform: 'capitalize',
                  height: '30px',
                  width: '30px',
                  minWidth: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': {
                    opacity: '1',
                  },
                }}
              >
                <img src={PrintIcon} />
              </Button>
            </Tooltip>
          </Box>
          <Box
            sx={{
              marginRight: '15px',
            }}
          >
            <Tooltip title={'Pdf Print'} placement="top" arrow>
              <Button
                sx={{
                  backgroundColor: 'transparent',
                  borderRadius: '20px',
                  color: '#3F2F5B',
                  textTransform: 'capitalize',
                  height: '30px',
                  width: '30px',
                  minWidth: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': {
                    opacity: '1',
                  },
                }}
                onClick={generatePDF}
              >
                <PrintLabelIcon />
              </Button>
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '15px',
            justifyContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
            mr: 2,
          }}
        >
          <Button
            sx={{
              backgroundColor: '#FF7676',
              color: 'white',
              height: '40px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              minWidth: '90px',
              width: 'inherit',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              fontSize: '14px',
              '&:hover': {
                backgroundColor: '#FF7676',
                color: 'white',
              },
            }}
            type="Button"
          >
            <span>Search</span>
          </Button>
        </Box>
      </Box>
      {open && <RouteModal open={open} handleClose={handleClose} />}
    </Box>
  );
};

export default PickupGridHeader;
