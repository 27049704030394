import { Switch, Typography } from '@mui/material';
import WebCloseTime from '../webAddForm/webCloseTime';
import WebStartTime from '../webAddForm/webStartTime';
import WebSwitch from '../webAddForm/webSwitch/webSwitch';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const RouteColumns = [
  {
    field: 'days',
    headerName: 'Days',
    flex: 1,
    minWwidth: 220,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
  },

  // {
  //   field: 'opening',
  //   headerName: 'Opening Time',
  //   flex:1,
  //   headerClassName: 'action-cell',
  //   cellClassName: 'column-cell',
  //   headerAlign: 'center',
  //   align: 'center',
  //   minWidth:75 ,
  //   renderCell: (params) => <WebStartTime openingTime={params.row.opening_time} id={params.row.id} />,
  // },
  {
    field: 'closing',
    headerName: 'Timing',
    flex: 1,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    minWidth: 190,
    renderCell: (params) => (
      // <WebCloseTime closingTime={params.row.closing_time} id={params.row.id} />
      <Typography>7:00PM - 11:00PM</Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',

    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    alignItem: 'center',
    minWidth: 80,
    renderCell: (params) => (
      // <WebSwitch checked={params.row.status} row={params.row} />
      <Switch {...label} defaultChecked />
    ),
  },
];

export default RouteColumns;
