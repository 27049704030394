import { Image } from '@mui/icons-material';
import PickupGroupActionColumn from './pickupGroupActionColumn';

const PickupGroupColumns = [
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    fontFamily: 'Outfit',
    minWidth: 100,
    renderCell: (params) => (
      <div
        style={{
          fontSize: '13px',
          fontFamily: 'Outfit',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <img
          src="https://images.unsplash.com/photo-1597645587822-e99fa5d45d25"
          alt=""
          style={{ width: 40, height: 40, borderRadius: '50%' }}
        />
        {params.row.name}
      </div>
    ),
  },
  {
    field: 'phonenumber',
    headerName: 'Phone Number',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,

    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px', fontFamily: 'Outfit' }}>
        {params.row.phonenumber}
      </div>
    ),
  },
  {
    field: 'mealitems',
    headerName: 'Meal Items',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,

    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px', fontFamily: 'Outfit' }}>
        {params.row.mealitems}
      </div>
    ),
  },
  {
    field: 'subscriptionplan',
    headerName: 'Subscription Plan',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,

    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px', fontFamily: 'Outfit' }}>
        {params.row.subscriptionplan}
      </div>
    ),
  },
  {
    field: 'startdate',
    headerName: 'Start Date',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px', fontFamily: 'Outfit' }}>
        {params.row.startdate}
      </div>
    ),
  },
  {
    field: 'pickupstatus',
    headerName: 'Pickup Status',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,

    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor:
              params.row.order_status === 'Picked Up'
                ? ' rgb(77, 170, 68)'
                : params.row.order_status === 'Cancelled'
                ? 'orange'
                : 'rgb(219, 51, 51)',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px', fontFamily: 'Outfit' }}>
          {params.row.pickupstatus}
        </p>
      </>
    ),
  },
  {
    field: 'actions',
    headerName: '  Actions',
    flex: 1,
    minWidth: 70,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return <PickupGroupActionColumn id={params.row.customer_id} />;
    },
  },
];

export default PickupGroupColumns;
