import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Grid,
  Container,
  Box,
} from '@mui/material';
import TextfieldLight from '../../styles/TextFieldStyles/TextFieldLight/TextFieldLight1';
import Textarea from '../../styles/TextFieldStyles/customTextarea/CustomTextarea';
const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    phoneNumber: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here using formData
    console.log('Form submitted with data:', formData);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Typography
          variant="h6"
          style={{
            fontSize: '19px',
            fontWeight: 700,
            letterSpacing: 2,
            textAlign: 'center',
            color: '#F96221',
            textTransform: 'uppercase',
          }}
          fontFamily={'Inter !important'}
          gutterBottom
        >
          Contact US
        </Typography>

        <Typography
          variant="body1"
          fontFamily={'Inter !important'}
          style={{
            fontSize: '35px',
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#000000',
          }}
          marginBottom={4}
          paragraph
        >
          Get in Touch With Us
        </Typography>

        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: '40px 6em',
            borderRadius: '30px',
            boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.043)',
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={12}>
              <TextfieldLight
                value={''}
                placeholder="Full Name"
                border="1px solid #4A4A4A"
                backgroundColor="#ffffff"
              />
            </Grid>
            <Grid item xs={12}>
              <TextfieldLight
                border="1px solid #4A4A4A"
                backgroundColor="#ffffff"
                value={''}
                placeholder="Email Address"
              />
            </Grid>
            <Grid item xs={12}>
              <TextfieldLight
                border="1px solid #4A4A4A"
                backgroundColor="#ffffff"
                value={''}
                placeholder="Subject"
              />
            </Grid>
            <Grid item xs={12}>
              <Textarea
                border="1px solid #4A4A4A"
                backgroundColor="#ffffff"
                value={''}
                placeholder="Query"
              />
            </Grid>
          </Grid>

          <Box textAlign={'center'}>
            <Button
              type="submit"
              variant="filled"
              fullWidth
              sx={{
                maxWidth: '170px',
                height: '48px',
                padding: '13px, 36px, 13px, 36px',
                boxShadow: 'none',
                fontFamily: 'Inter !important',
                marginTop: '16px',
                fontSize: '16pd',
                fontWeight: '600',
                lineHeight: '22px',
                borderRadius: '100px',
                color: '#ffffff',
                bgcolor: '#F96221',
                textTransform: 'capitalize',
                ':hover': {
                  bgcolor: 'rgba(228, 186, 103, 1)',
                  boxShadow: ' 0px 2px 0px 0px rgba(0, 0, 0, 0.043)',
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </Container>
  );
};

export default ContactUsForm;
