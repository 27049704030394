import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import TopCards from './component/cards/topCards';
import ProgressCard from './component/cards/progressCard';
import { useDeliveryGroup } from '../../customHooks/useDeliveryGroup';
import { DataGrid } from '@mui/x-data-grid';
import { UtilityProvider } from './useUtility';
import { useTheme } from '@emotion/react';
import Spinner from '../../../components/utils/spinner';
import DeliveryGroupColumns from './_columns';
import Pagination from './pagination';
import DeliveryGridHeader from './deliveryGroupHeader';

import DangerImage from '../../assets/dashboard/danger.svg';
import TodayImage from '../../assets/deliveryGroup/today.svg';
import ActiveImage from '../../assets/deliveryGroup/active.svg';
import DuePaymentImage from '../../assets/deliveryGroup/duePaymen.svg';

const DeliveryGroup = () => {
  const {
    deliveryGroupList,
    loading,

    pagedList,
    cardData,
    routes,
    dateRange,
    setDateRange,
    setKeyword,
    keyword,
    setRoute,
    route,
    meta,
  } = useDeliveryGroup();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterBy, setFilterBy] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const dateOnClick = () => {
    if (dateRange.length === 0) {
      return;
    }

    let sd = new Date(dateRange[0].startDate);
    const day1 = String(sd.getDate()).padStart(2, '0');
    const month1 = String(sd.getMonth() + 1).padStart(2, '0');
    const year1 = sd.getFullYear();
    const formattedDate1 = `${day1}/${month1}/${year1}`;
    setStartDate(formattedDate1);
    let ed = new Date(dateRange[0].endDate);
    const day2 = String(ed.getDate()).padStart(2, '0');
    const month2 = String(ed.getMonth() + 1).padStart(2, '0');
    const year2 = ed.getFullYear();
    const formattedDate2 = `${day2}/${month2}/${year2}`;
    setEndDate(formattedDate2);

    let rows = deliveryGroupList.filter((item) => {
      const std = new Date(item.subscription_start_date);
      return std >= sd && std <= ed;
    });
    setList(rows);
  };

  useEffect(() => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    setStartDate(formattedDate);
    setEndDate(formattedDate);
  }, []);

  // useEffect(() => {
  //   const filteredRows = deliveryGroupList.filter(
  //     (row) => row.route_name === route
  //   );
  //   setList(filteredRows);
  // }, [route]);

  // const searchFilter = () => {
  //   if (keyword === '') {
  //     setList(pagedList);
  //     return;
  //   }
  //   let rows = deliveryGroupList.filter((entry) =>
  //     entry[filterBy].toString().toLowerCase().includes(keyword.toLowerCase())
  //   );
  //   setList(rows);
  // };
  // useEffect(() => {
  //   if (keyword === '') {
  //     setList(pagedList);
  //     return;
  //   }
  //   let rows = deliveryGroupList.filter((entry) =>
  //     entry['name'].toString().toLowerCase().includes(keyword.toLowerCase())
  //   );
  //   setList(rows);
  // }, [keyword]);
  // useEffect(() => {
  //   // if (pagedList.length !== 0) setList(pagedList);
  // }, [pagedList]);

  const handleSelectedRowsChange = (selectionModel) => {
    let selectRowArray = [];
    selectionModel.forEach((rowID) => {
      const selectRow = list.find((row) => row.id === rowID);
      selectRowArray.push(selectRow);
    });
    setSelectedRows(selectRowArray);
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={'24px'}
            justifyContent={isSmUp ? 'flex-start' : 'center'}
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ProgressCard
                amount={cardData.delivery_cycle_percent}
                text={"Today's Delivery Cycle"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.3}>
              <TopCards
                imageWidth={'min(70px, 40%)'}
                imageSrc={DangerImage}
                imageAlt={'sale_image'}
                amount={cardData.halted_count}
                text={'Delivery Halted Today'}
                color={'#fc6464'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.3}>
              <TopCards
                imageWidth={'min(70px, 40%)'}
                imageSrc={TodayImage}
                imageAlt={'sale_image'}
                amount={cardData.route_count}
                text={"Today's Route"}
                color={'#0dc2cd'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.3}>
              <TopCards
                imageWidth={'min(70px, 40%)'}
                imageSrc={ActiveImage}
                imageAlt={'sale_image'}
                amount={cardData.active_subscriber_count}
                text={'Active Subscriber'}
                color={'#54ba4a'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.1}>
              <TopCards
                imageWidth={'min(70px, 40%)'}
                imageSrc={DuePaymentImage}
                imageAlt={'sale_image'}
                amount={cardData.due_payment}
                text={'Payment Due Users'}
                color={'#ff7676'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <UtilityProvider>
        {loading ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              borderRadius: '15px',
              boxShadow: '0px 4px 4px 0px #00000026',
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '20px',
                boxSizing: 'border-box',
              }}
            >
              <DeliveryGridHeader
                keyword={keyword}
                setKeyword={setKeyword}
                searchBy={filterBy}
                setSearchBy={setFilterBy}
                // onClickFunc={searchFilter}
                route={route}
                setRoute={setRoute}
                list={routes}
                dateRange={dateRange}
                setDateRange={setDateRange}
                startDate={startDate}
                endDate={endDate}
                dateonClick={dateOnClick}
              />
              <DataGrid
                sx={{
                  // borderBottom: '1px solid white',
                  width: '100%',
                  border: 'none',
                  display: 'flex',
                  overflow: 'auto',
                  borderTop: '1px solid #F4F1F8',
                  justifyContent: 'space-between',
                  '& .MuiDataGrid-withBorderColor': {
                    border: 'none',
                  },
                  '& .column-cell': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                    width: '99%',
                    borderBottom: '1px solid white',
                  },
                  '& .header-cell': {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    width: '100%',
                    borderBottom: '3px solid white',
                  },
                  '& .action-cell': {
                    fontSize: '18px',
                    fontWeight: 'bold',

                    // paddingRight:{lg:'2.8rem',md:'2.5rem',sm:'1.3rem',xs:'1.3rem'},
                    borderBottom: '3px solid white',
                  },
                  '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'normal',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '--unstable_DataGrid-radius': 'none',
                }}
                rows={list}
                columns={DeliveryGroupColumns}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                onRowSelectionModelChange={handleSelectedRowsChange}
              />
              <Box
                sx={{
                  m: 'auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  py: 0.5,
                  mt: 0.4,
                }}
              >
                <span>
                  {/* Showing {meta.from ?? 0} to {meta.to ?? 0} of {meta.total}{' '}
                  entries */}
                </span>
                <Pagination />
              </Box>
            </Box>
          </Box>
        )}
      </UtilityProvider>
    </>
  );
};

export default DeliveryGroup;
