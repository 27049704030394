import React, { useState } from 'react';
import { Drawer, List, ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import { headerHeight } from './CustomizationLandingPageLayout';
import { useSnackbar } from '../../../../../../components/utils/snackbarProvider/SnackbarContextProvider';
import { useStateContext } from '../../../api/stateContext';
import axios from 'axios';
import { TENANT_URL } from '../../../../../../config';
const drawerWidth = 200;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    position: 'static !important',
    height: `calc(100vh - ${headerHeight} - 48px)`,
    width: '201px',
    borderRadius: '15px',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 100%)',
    padding: '24px 16px',
    justifyContent: 'space-between',

    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      '& .MuiListItemButton-root': {
        height: '49px',
        padding: '14px, 16px, 14px, 16px',
        borderRadius: '9px',
        gap: '10px',
        transition: 'background .3s ',

        '& .MuiTypography-root': {
          fontSize: '18px',
          fontWeight: '500',
          lineHeight: '21px',
          letterSpacing: '0px',
          textAlign: 'left',
          color: '#334155',
        },

        '&:hover': {
          background: '#DF55501A',
        },

        '&.Mui-selected': {
          background: 'linear-gradient(95.07deg, #F77252 3.98%, #E05750 80.2%)',
          '& .MuiTypography-root': {
            color: '#FFFFFF',
          },
        },
        '&.save-btn': {
          background: '#FF7676',
          '& .MuiTypography-root': {
            textAlign: 'center',
            color: '#FFFFFF',
          },
        },
        '&.publish-btn': {
          border: '1px solid #FF7676',
          background: 'transparent',
          '& .MuiTypography-root': {
            textAlign: 'center',
            color: '#FF7676',
          },
        },
      },
    },
  },
}));

const Navigations = ({ onLinkClick }) => {
  const [activeItem, setActiveItem] = useState(0);
  const { state } = useStateContext();
  const { show } = useSnackbar();

  const handleListItemClick = (index) => {
    setActiveItem(index);
    onLinkClick(index);
  };

  const handleSave = () => {
    // Assuming you have the stateContext object defined here

    const tenantdata = JSON.parse(localStorage.getItem('tenantdata'));
    const tenantId =
      tenantdata.tenant_nav_bar_permission.tenant_permissions.tenant_id;
    // const endpoint = `${process.env.REACT_APP_BACKEND_URL}/service/landing/page/${tenantId}`;
    const endpoint = `${TENANT_URL}/service/landing/page/${tenantId}`;
    const authToken = tenantdata.token;

    const data = {
      tenant_id: tenantId,
      active_tab: state.activeTab.toString(),
      active_font: state.activeFont,
      active_card_style_menu: state.activeCardStyleMenu.toString(),
      active_card_style_our_special: state.activeCardStyleOurSpecial.toString(),
      active_card_layout_menu: state.activeCardLayoutMenu.toString(),
      active_card_layout_our_special:
        state.activeCardLayoutOurSpecial.toString(),
      active_button: state.activeButton,
      active_landing_page: state.activeLandingPage.toString(),
      active_nav_footer: state.activeNavFooter.toString(),
      active_header: state.activeHeader.toString(),
      active_button_color: state.activeButtonColor,
      active_trial_section: state.activeTrialSection.toString(),
    };

    axios
      .put(endpoint, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
          // Include any additional headers if needed
        },
      })
      .then((response) => {
        show('Published Successfully!!');
        console.log('PUT request successful', response.data);
      })
      .catch((error) => {
        show('Something went wrong!', 'error');

        console.error('Error making PUT request', error);
      });
  };

  return (
    <StyledDrawer variant="permanent">
      <List>
        <ListItemButton
          disableRipple
          selected={activeItem === 0}
          onClick={() => handleListItemClick(0)}
        >
          <ListItemText primary="Hero Sections" />
        </ListItemButton>

        <ListItemButton
          disableRipple
          selected={activeItem === 1}
          onClick={() => handleListItemClick(1)}
        >
          <ListItemText primary="Cards layout" />
        </ListItemButton>
        <ListItemButton
          disableRipple
          selected={activeItem === 2}
          onClick={() => handleListItemClick(2)}
        >
          <ListItemText primary="Card Styles" />
        </ListItemButton>
        <ListItemButton
          disableRipple
          selected={activeItem === 3}
          onClick={() => handleListItemClick(3)}
        >
          <ListItemText primary="Buttons" />
        </ListItemButton>
        <ListItemButton
          disableRipple
          selected={activeItem === 4}
          onClick={() => handleListItemClick(4)}
        >
          <ListItemText primary="Fonts" />
        </ListItemButton>
        <ListItemButton
          disableRipple
          selected={activeItem === 5}
          onClick={() => handleListItemClick(5)}
        >
          <ListItemText primary="Footer" />
        </ListItemButton>
        <ListItemButton
          disableRipple
          selected={activeItem === 6}
          onClick={() => handleListItemClick(6)}
        >
          <ListItemText primary="Trial Sections" />
        </ListItemButton>
      </List>
      <List>
        <ListItemButton onClick={handleSave} className="save-btn" disableRipple>
          <ListItemText primary="Save" />
        </ListItemButton>
        <ListItemButton className="publish-btn" disableRipple>
          <ListItemText primary="Publish" />
        </ListItemButton>
      </List>
    </StyledDrawer>
  );
};

export default Navigations;
