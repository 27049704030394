import {
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from '../../../../components/utils/snackbarProvider/SnackbarContextProvider';
import SelectToleranceDays from './../webListDisplay/components/selectToleranceDays';
import axios from 'axios';
import { TENANT_URL } from '../../../../config';

function RestaurantSettings({ userName }) {
  const [currentToleranceDays, setCurrentToleranceDays] = useState('');
  const [renewalPeriod, setRenewalPeriod] = useState(0);
  const { show } = useSnackbar();
  const daysList = Array.from({ length: 30 }, (_, i) => i + 1);
  const [taxesList, setTaxesList] = useState([]);
  console.log("taxesList:",taxesList);

  useEffect(() => {
    if (userName) {
      getCurrentToleranceDays();
      fetchTaxes();
    }
  }, [userName]);

  const fetchTaxes = () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${TENANT_URL}/taxes`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setTaxesList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrentToleranceDays = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/get/restaurant/tolerance/${userName}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: res } = await axios(config);
      setCurrentToleranceDays(res.tolerance_days);
      setRenewalPeriod(res.renewal_period);
    } catch (error) {
      console.log(error);
      show(error.response.data.message, 'error');
    }
  };

  const updateCurrentToleranceDays = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'put',
      data: { tolerance_days: currentToleranceDays, renewal_period:renewalPeriod  },
      url: `${TENANT_URL}/restaurant/tolerance/setting/${userName}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await axios.request(config);
      await getCurrentToleranceDays();
      show('Update successfully');
    } catch (error) {
      console.log(error);
      show(error.response.data.message, 'error');
    }
  };

  const handleChange = async (e) => {
    setCurrentToleranceDays(e.target.value)
  };

  const handleChangeRenewal = async (e) => {
    setRenewalPeriod(e.target.value)
  };
  

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        padding: '18px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        borderRadius: '15px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',

        gap: '15px',
        marginBottom: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '15px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography variant="h5" fontWeight="500" fontSize={'22px'}>
            Restaurant Settings
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '24px',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="span"
              fontWeight="500"
              fontSize={'16px'}
              lineHeight={'17.64px'}
            >
              Tolerance Days
            </Typography>

            <SelectToleranceDays
              list={daysList}
              currentToleranceDays={currentToleranceDays}
              handleChange={handleChange}
              name="tolerance"
            />
          </Box>
          <Typography
            fontWeight="400"
            color={'#AC9EC3'}
            fontSize={'14px'}
            lineHeight={'17.64px'}
          >
            Set # of tolerance days for the payment due users
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '26px',
            borderLeft: 1,
            paddingLeft: '1em',
            marginLeft: '0.6em',
          }}
        >
          <Typography variant="h5" fontWeight="500" fontSize={'22px'}>
            Added Taxes
          </Typography>
            {taxesList.map((taxes) =>
              <Box sx={{ display: 'flex', gap: '24px', alignItems: 'center',}} >
                <Typography variant="span" fontWeight="500" fontSize={'16px'} lineHeight={'17.64px'}>
                    {taxes.name}: {taxes.value}%
                </Typography>
              </Box>
            )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '26px',
            borderLeft: 1,
            marginLeft: '0.6em',
            padding: '0 0.9em',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '24px',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <InputLabel
              style={{
                minWidth: 120,
              }}
              htmlFor="input-field"
            >
              Renewal Period
            </InputLabel>
            <FormControl>
              <TextField
                id="input-field"
                variant="outlined"
                placeholder="26"
                size="small"
                InputProps={{
                  height: '47px',
                }}
                value={renewalPeriod}
                onChange={handleChangeRenewal}
              />
              <Typography
                variant="h6"
                sx={{
                  position: 'absolute', // Fixed position
                  right: '10px', // 10px from the right edge
                  top: '50%', // Center vertically
                  transform: 'translateY(-50%)', // Adjust for perfect centering
                  fontWeight: '400',
                  color: '#AC9EC3',
                  fontSize: '14px',
                }}
              >
                Days
              </Typography>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          gap: '20px',
          padding: '0 20px',
          marginTop: '20px',
        }}
      >
        <Button
          variant="outline"
          sx={{
            color: '#AC9EC3',
            textDecoration: 'underline',
            fontWeight: 500,
            minWidth: 60,
          }}
        >
          Reset
        </Button>

        <Button variant="filled" type="button" onClick={updateCurrentToleranceDays}>
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default RestaurantSettings;
