import { ListItemButton, ListItemText } from '@mui/material';
import React, { cloneElement } from 'react';
import { Link } from 'react-router-dom';

import {
  buttonStyles,
  buttonTextStyles,
  selectedButtonStyles,
  selectedButtonTextStyles,
} from '../config';

const SidebarItem = ({ name, path, onClick, src, selectedButton,showHoverSidebar }) => {
  return (
    <Link key={name} to={path} style={{ textDecoration: 'none' }}>
      <ListItemButton
        key={name}
        onClick={onClick}
        sx={selectedButton === name && showHoverSidebar ? selectedButtonStyles : buttonStyles}
      >
        {cloneElement(src, {
          style: {
            fill: selectedButton === name ? '#646262' : 'white',
            width: '24px',
          },
        })}
        <ListItemText
          key={name}
          sx={
            selectedButton === name
              ? selectedButtonTextStyles
              : buttonTextStyles
          }
          primary={name}
        />
      </ListItemButton>
    </Link>
  );
};

export default SidebarItem;
