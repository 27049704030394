import React, { useEffect, useState } from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { UtilityProvider } from '../useUtility';
import { useRoute } from '../../../customHooks/useRoute';
import { useTheme } from '@emotion/react';
import WebSettingColumns from './_columns';
import Spinner from '../../../../components/utils/spinner';
import Pagination from './pagination';
import WebSettingListDisplayHeader from './webListDisplayHeader';
import { useRestaurant } from '../../../customHooks/useRestaurants';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';

const WebSettingListDisplay = () => {
  const theme = useTheme();
  const { restaurantList } = useRestaurant();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { webSettingList, loading, pagedList } = useRoute();
  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [bulkAction, setBulkAction] = useState('');
  const [renderList, setRenderList] = useState([]);
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_web_setting_tab');

  useEffect(() => {
    if (restaurantList.length > 0)
      setRenderList([
        {
          id: 'monday',
          days: 'Monday',
          status: restaurantList[0].monday,
          closing_time: '7:00PM - 11:00PM',
        },
        {
          id: 'tuesday',
          days: 'Tuesday',
          status: restaurantList[0].tuesday,
          closing_time: '7:00PM - 11:00PM',
        },
        {
          id: 'wednesday',
          days: 'Wednesday',
          status: restaurantList[0].wednesday,
          closing_time: '7:00PM - 11:00PM',
        },
        {
          id: 'thursday',
          days: 'Thursday',
          status: restaurantList[0].thursday,
          closing_time: '7:00PM - 11:00PM',
        },
        {
          id: 'friday',
          days: 'Friday',
          status: restaurantList[0].friday,
          closing_time: '7:00PM - 11:00PM',
        },
        {
          id: 'saturday',
          days: 'Saturday',
          status: restaurantList[0].saturday,
          closing_time: restaurantList[0].saturday_closing,
        },
        {
          id: 'sunday',
          days: 'Sunday',
          status: restaurantList[0].sunday,
          closing_time: '7:00PM - 11:00PM',
        },
      ]);
  }, [restaurantList]);

  const handleSelectedRowsChange = (selectionModel) => {
    let selectRowArray = [];
    selectionModel.forEach((rowID) => {
      const selectRow = list.find((row) => row.id === rowID);
      selectRowArray.push(selectRow);
    });
    setSelectedRows(selectRowArray);
  };

  return (
    <UtilityProvider>
      {loading ? (
        <Box
          sx={{
            flex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          sx={{
            flex: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            width: '100%',
            height: 'auto',
            borderRadius: '15px',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '20px 0',
            boxSizing: 'border-box',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              padding: '0 20px',
              width: '100%',
            }}
          >
            <WebSettingListDisplayHeader
              keyword={keyword}
              setKeyword={setKeyword}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              bulkId={selectedRows}
              permissions={permissions}
            />
          </Box>
          <DataGrid
            sx={{
              width: '100%',
              border: 'none',
              display: 'flex',
              justifyContent: 'space-between',

              '& .MuiDataGrid-virtualScrollerContent': {
                height: 'auto !important',
              },
              '& .MuiDataGrid-withBorderColor': {
                border: 'none',
              },
              '& .column-cell': {
                padding: '10px 25px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                fontWeight: '500',
                whiteSpace: 'nowrap',
                width: '100%',
                borderBottom: '1px solid white',
              },
              '& .header-cell': {
                fontSize: '18px',
                fontWeight: 'bold',
                width: '100%',
                padding: '10px 25px',
                borderBottom: '1px solid white',
              },
              '& .action-cell': {
                fontSize: '18px',
                fontWeight: 'bold',
                padding: '10px 25px',
                borderBottom: '1px solid white',
              },
              '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-cellContent': {
                fontFamily: 'Outfit',
                fontSize: '16px',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
              '--unstable_DataGrid-radius': 'none',
              borderTop: '1px solid white',
            }}
            rows={renderList}
            columns={WebSettingColumns}
            hideFooter
            disableColumnFilter
            disableColumnSelector
            onRowSelectionModelChange={handleSelectedRowsChange}
          />

          <Box
            sx={{
              padding: '0 20px',
              position: 'relative',
              top: '-14px',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: '#3F2F5B',
                borderColor: '#3F2F5B',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              Custom date for close <CalendarIcon />
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              gap: '20px',
              padding: '0 20px',

              width: '100%',
            }}
          >
            <Button
              variant="outline"
              sx={{
                color: '#AC9EC3',
                textDecoration: 'underline',
                fontWeight: 500,
                minWidth: 60,
              }}
            >
              Reset
            </Button>

            <Button variant="filled" type="button">
              Save
            </Button>
          </Box>
        </Box>
      )}
    </UtilityProvider>
  );
};

export default WebSettingListDisplay;
