import React from 'react';
import { Box } from "@mui/material";
import Button1 from "../Button1/Button1";
import Button2 from "../Button2/Button2";
import Button3 from "../Button3/Button3";
import Button4 from "../Button4/Button4";
import Button5 from "../Button5/Button5";
import Button6 from "../Button6/Button6";
import Button7 from "../Button7/Button7";
import Button8 from "../Button8/Button8";

// Import Outline components
import Outline1 from "../Outline1/Outline1";
import Outline2 from "../Outline2/Outline2";
import Outline3 from "../Outline3/Outline3";
import Outline4 from "../Outline4/Outline4";
import Outline5 from "../Outline5/Outline5";
import Outline6 from "../Outline6/Outline6";
import Outline7 from "../Outline7/Outline7";
import Outline8 from "../Outline8/Outline8";
import { useStateContext } from "../../../api/stateContext";
import Button9 from '../Button9/Button9';
import Button10 from '../Button10/Button10';
import Button11 from '../Button11/Button11';
import Button12 from '../Button12/Button12';
import Button13 from '../Button13/Button13';
import Button14 from '../Button14/Button14';
import Button15 from '../Button15/Button15';
import Button16 from '../Button16/Button16';
import Button17 from '../Button17/Button17';
import Button18 from '../Button18/Button18';
import Button19 from '../Button19/Button19';
import Button20 from '../Button20/Button20';
import Button21 from '../Button21/Button21';
import Button22 from '../Button22/Button22';

export default function ButtonStyleToBeRendered({ text,sx }) {
  const { state } = useStateContext();
  const selectedButtonStyle = state.activeButton;
  const activeButtonColor = state.activeButtonColor;

  const buttonComponents = {
    Button1: <Button1 sx={sx} backgroundColor={activeButtonColor}>{text}</Button1>,
    Button2: <Button2 sx={sx} backgroundColor={activeButtonColor}>{text}</Button2>,
    Button3: <Button3 sx={sx} backgroundColor={activeButtonColor}>{text}</Button3>,
    Button4: <Button4 sx={sx} backgroundColor={activeButtonColor}>{text}</Button4>,
    Button5: <Button5 sx={sx} backgroundColor={activeButtonColor}>{text}</Button5>,
    Button6: <Button6 sx={sx} backgroundColor={activeButtonColor}>{text}</Button6>,
    Button7: <Button7 sx={sx} backgroundColor={activeButtonColor}>{text}</Button7>,
    Button8: <Button8 sx={sx} backgroundColor={activeButtonColor}>{text}</Button8>,
    Button9: <Button9 sx={sx} backgroundColor={activeButtonColor}>{text}</Button9>,
    Button10: <Button10 sx={sx} backgroundColor={activeButtonColor}>{text}</Button10>,
    Button11: <Button11 sx={sx} backgroundColor={activeButtonColor}>{text}</Button11>,
    Button12: <Button12 sx={sx} backgroundColor={activeButtonColor}>{text}</Button12>,
    Button13: <Button13 sx={sx} backgroundColor={activeButtonColor}>{text}</Button13>,
    Button14: <Button14 sx={sx} backgroundColor={activeButtonColor}>{text}</Button14>,
    Button15: <Button15 sx={sx} backgroundColor={activeButtonColor}>{text}</Button15>,
    Button16: <Button16 sx={sx} backgroundColor={activeButtonColor}>{text}</Button16>,
    Button17: <Button17 sx={sx} backgroundColor={activeButtonColor}>{text}</Button17>,
    Button18: <Button18 sx={sx} backgroundColor={activeButtonColor}>{text}</Button18>,
    Button19: <Button19 sx={sx} backgroundColor={activeButtonColor}>{text}</Button19>,
    Button20: <Button20 sx={sx} backgroundColor={activeButtonColor}>{text}</Button20>,
    Button21: <Button21 sx={sx} backgroundColor={activeButtonColor}>{text}</Button21>,
    Button22: <Button22 sx={sx} backgroundColor={activeButtonColor}>{text}</Button22>,
  };

  const outlineComponents = {
    Outline1: <Outline1 sx={sx} backgroundColor={activeButtonColor}>{text}</Outline1>,
    Outline2: <Outline2 sx={sx} backgroundColor={activeButtonColor}>{text}</Outline2>,
    Outline3: <Outline3 sx={sx} backgroundColor={activeButtonColor}>{text}</Outline3>,
    Outline4: <Outline4 sx={sx} backgroundColor={activeButtonColor}>{text}</Outline4>,
    Outline5: <Outline5 sx={sx} backgroundColor={activeButtonColor}>{text}</Outline5>,
    Outline6: <Outline6 sx={sx} backgroundColor={activeButtonColor}>{text}</Outline6>,
    Outline7: <Outline7 sx={sx} backgroundColor={activeButtonColor}>{text}</Outline7>,
    Outline8: <Outline8 sx={sx} backgroundColor={activeButtonColor}>{text}</Outline8>,
  };

  const renderButtonStyle = () => {
    if (buttonComponents[selectedButtonStyle]) {
      return buttonComponents[selectedButtonStyle];
    } else if (outlineComponents[selectedButtonStyle]) {
      return outlineComponents[selectedButtonStyle];
    } else {
      return null;
    }
  };

  return (
      renderButtonStyle()
  );
}
