import React, { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { drawerListStyles } from './config';
import SidebarLogo from './SidebarLogo/SidebarLogo';
import SidebarItem from './SidebarItem/SidebarItem';
import { ReactComponent as DashboardIcon } from '../../../icons/dashboardDraw.svg';
import { ReactComponent as FoodMenuIcon } from '../../../icons/mealmenuDraw.svg';
import { ReactComponent as FoodCategoryIcon } from '../../../icons/mealcategoryDraw.svg';
import { ReactComponent as FoodItemsIcon } from '../../../icons/mealitemDraw.svg';
import { ReactComponent as FoodMealIcon } from '../../../icons/mealDraw.svg';
import { ReactComponent as FoodAddonIcon } from '../../../icons/addonDraw.svg';
import { ReactComponent as FoodPlanCategoryIcon } from '../../../icons/mealplancategoryDraw.svg';
import { ReactComponent as FoodPlanIcon } from '../../../icons/mealplanDraw.svg';
import { ReactComponent as MarketingIcon } from '../../../icons/bannerDraw.svg';
import { ReactComponent as CustomersIcon } from '../../../icons/customerDraw.svg';
import { ReactComponent as RidersIcon } from '../../../icons/riderDraw.svg';
import { ReactComponent as MessagesIcon } from '../../../icons/messageDraw.svg';
import { ReactComponent as UsersIcon } from '../../../icons/userDraw.svg';
import { ReactComponent as DeliveryGroupIcon } from '../../../icons/deliverygroupDraw.svg';
import { ReactComponent as RolesIcon } from '../../../icons/rolesDraw.svg';
import { ReactComponent as ArchiveIcon } from '../../../icons/archiveDraw.svg';
import { ReactComponent as SettingsIcon } from '../../../icons/settingDraw.svg';
import { ReactComponent as WebSettingsIcon } from '../../../icons/websettingDraw.svg';
import { ReactComponent as RouteSettingsIcon } from '../../../icons/routesettingDraw.svg';
import { ReactComponent as OnlineStoreIcon } from '../../../icons/onlinestoreDraw.svg';
import { ReactComponent as CampaignIcon } from '../../../icons/campaignDraw.svg';
import { ReactComponent as NotificationIcon } from '../../../icons/notificationDraw.svg';
import { ReactComponent as KitchenIcon } from '../../../icons/kitchenDraw.svg';
import { ReactComponent as MealIcon } from '../../../icons/meal.svg';
import { ReactComponent as PicupGroupIcon } from '../../../icons/pickupgroup.svg';
import { ReactComponent as TrialsIcon } from '../../../icons/trails.svg';
import { Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
function Sidebar({
  leftSpan,
  sideBarCollapse,
  isDrawerOpen,
  setIsDrawerOpen,
  handleMainDrawerCloseToggle,
}) {
  const [selectedButton, setSelectedButton] = useState('Dashboard');
  const [hide, setHide] = useState(false);
  useEffect(() => {
    let timeoutId;

    if (sideBarCollapse) {
      timeoutId = setTimeout(() => {
        setHide(true);
      }, 300);
    } else {
      clearTimeout(timeoutId);
      setHide(false);
    }

    // Cleanup function
    return () => clearTimeout(timeoutId);
  }, [sideBarCollapse]);
  const location = useLocation();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [showFoodMenus, setShowFoodMenus] = useState(false);
  const [showSettingNavs, setShowSettingNavs] = useState(false);
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getIsActiveByModuleName(data, module_name) {
    for (const permission of data) {
      if (permission.module_name === module_name) {
        return permission.view;
      }
    }
    return false;
  }

  const buttons = [
    {
      path: '/admin',
      name: 'Dashboard',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Dashboard');
      },
      imgSrc: <DashboardIcon />,
      active:
        tenant_permissions.is_dashboard_tab &&
        genral_permissions.is_dashboard_tab &&
        getIsActiveByModuleName(user_permission, 'is_dashboard_tab'),
    },
    {
      path: getIsActiveByModuleName(user_permission, 'is_food_category_tab')
        ? '/admin/foodcategory'
        : getIsActiveByModuleName(user_permission, 'is_food_item_tab')
        ? '/admin/fooditems'
        : getIsActiveByModuleName(user_permission, 'is_meal_tab')
        ? '/admin/foodmeal'
        : getIsActiveByModuleName(user_permission, 'is_addon_tab')
        ? '/admin/adons'
        : getIsActiveByModuleName(user_permission, 'is_plan_category_tab')
        ? '/admin/plancategory'
        : getIsActiveByModuleName(user_permission, 'is_menu_plan_tab')
        ? '/admin/menuplan'
        : null,
      name: 'Meal Menu',
      type: 'FoodMenu',
      parentLink: true,
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(true);
        setShowSettingNavs(false);
        handleButtonClick('Meal Category');
      },
      imgSrc: <FoodMenuIcon />,
      active:
        tenant_permissions.is_food_menu_tab &&
        genral_permissions.is_food_menu_tab &&
        getIsActiveByModuleName(user_permission, 'is_food_menu_tab'),
      // tenant_permissions.is_food_category_tab &&
      // genral_permissions.is_food_category_tab &&
      // getIsActiveByModuleName(user_permission, 'is_food_category_tab'),
    },
    {
      path: '/admin/customers',
      name: 'Customers',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Customers');
      },
      imgSrc: <CustomersIcon />,
      active:
        tenant_permissions.is_customer_tab &&
        genral_permissions.is_customer_tab &&
        getIsActiveByModuleName(user_permission, 'is_customer_tab'),
    },
    {
      path: '/admin/deliverygroups',
      name: 'Delivery Groups',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Delivery Groups');
      },
      imgSrc: <DeliveryGroupIcon />,
      active:
        tenant_permissions.is_delivery_groups_tab &&
        genral_permissions.is_delivery_groups_tab &&
        getIsActiveByModuleName(user_permission, 'is_delivery_groups_tab'),
    },
    {
      path: '/admin/pickupgroup',
      name: 'Pickup Groups',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Pickup Groups');
      },
      imgSrc: <PicupGroupIcon />,
      active:
        tenant_permissions.is_delivery_groups_tab &&
        genral_permissions.is_delivery_groups_tab &&
        getIsActiveByModuleName(user_permission, 'is_delivery_groups_tab'),
    },
    {
      path: '/admin/kitchen',
      name: 'Kitchen',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Kitchen');
      },
      imgSrc: <KitchenIcon />,
      active:
        tenant_permissions.is_kitchen_tab &&
        genral_permissions.is_kitchen_tab &&
        getIsActiveByModuleName(user_permission, 'is_kitchen_tab'),
    },
    // {
    //   path: '/admin/mealplus',
    //   name: 'Meal +',
    //   onClick: () => {
    //     setIsDrawerOpen(false);
    //     setShowFoodMenus(false);
    //     setShowSettingNavs(false);
    //     handleButtonClick('MealIcon');
    //   },
    //   imgSrc: <MealIcon />,
    //   active:
    //     tenant_permissions.is_delivery_groups_tab &&
    //     genral_permissions.is_delivery_groups_tab &&
    //     getIsActiveByModuleName(user_permission, 'is_delivery_groups_tab'),
    // },
    {
      path: '/admin/trail',
      name: 'Trials',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Trials');
      },
      imgSrc: <TrialsIcon />,
      active:
        tenant_permissions.is_delivery_groups_tab &&
        genral_permissions.is_delivery_groups_tab &&
        getIsActiveByModuleName(user_permission, 'is_delivery_groups_tab'),
    },
    {
      path: '/admin/messages',
      name: 'Messages',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Messages');
      },
      imgSrc: <MessagesIcon />,
      active:
        tenant_permissions.is_message_tab &&
        genral_permissions.is_message_tab &&
        getIsActiveByModuleName(user_permission, 'is_message_tab'),
    },

    {
      path: '/admin/campaign',
      name: 'Campaigns',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Campaign');
      },
      imgSrc: <CampaignIcon />,
      active:
        tenant_permissions.is_food_campaign_tab &&
        genral_permissions.is_food_campaign_tab &&
        getIsActiveByModuleName(user_permission, 'is_food_campaign_tab'),
    },

    {
      path: '/admin/notification',
      name: 'Notification',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Notification');
      },
      imgSrc: <NotificationIcon />,
      active:
        tenant_permissions.is_food_campaign_tab &&
        genral_permissions.is_food_campaign_tab &&
        getIsActiveByModuleName(user_permission, 'is_food_campaign_tab'),
    },
    {
      path: '/admin/marketing',
      name: 'Marketing',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Marketing');
      },
      imgSrc: <MarketingIcon />,
      active:
        tenant_permissions.is_food_campaign_tab &&
        genral_permissions.is_food_campaign_tab &&
        getIsActiveByModuleName(user_permission, 'is_food_campaign_tab'),
    },
    {
      path: '/admin/riders',
      name: 'Riders',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Riders');
      },
      imgSrc: <RidersIcon />,
      active:
        tenant_permissions.is_delivery_boy_tab &&
        genral_permissions.is_delivery_boy_tab &&
        getIsActiveByModuleName(user_permission, 'is_delivery_boy_tab'),
    },
    {
      path: '/admin/roles',
      name: 'Roles',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Roles');
      },
      imgSrc: <RolesIcon />,
      active:
        tenant_permissions.is_roles_tab &&
        genral_permissions.is_roles_tab &&
        getIsActiveByModuleName(user_permission, 'is_roles_tab'),
    },
    {
      path: '/admin/users',
      name: 'Users',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Users');
      },
      imgSrc: <UsersIcon />,
      active:
        tenant_permissions.is_users_tab &&
        genral_permissions.is_users_tab &&
        getIsActiveByModuleName(user_permission, 'is_users_tab'),
    },
    {
      path: '/admin/customizationServiceLandingPage',
      name: 'Online Store',

      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Customize SLP');
      },
      imgSrc: <OnlineStoreIcon />,
      active:
        tenant_permissions.is_customize_slp_tab &&
        genral_permissions.is_customize_slp_tab &&
        getIsActiveByModuleName(user_permission, 'is_customize_slp_tab'),
    },
    {
      path: getIsActiveByModuleName(user_permission, 'is_route_tab')
        ? '/admin/routesetting'
        : getIsActiveByModuleName(user_permission, 'is_web_setting_tab')
        ? '/admin/websetting'
        : null,
      name: 'Settings',
      type: 'Settings',
      parentLink: true,
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(true);
        handleButtonClick('Route Setting');
      },
      imgSrc: <SettingsIcon />,
      active:
        tenant_permissions.is_setting_tab &&
        genral_permissions.is_setting_tab &&
        getIsActiveByModuleName(user_permission, 'is_setting_tab'),
    },
    {
      path: '/admin/archive',
      name: 'Archive',
      onClick: () => {
        setIsDrawerOpen(false);
        setShowFoodMenus(false);
        setShowSettingNavs(false);
        handleButtonClick('Archive');
      },
      imgSrc: <ArchiveIcon />,
      active:
        tenant_permissions.is_customer_archive_tab &&
        genral_permissions.is_customer_archive_tab &&
        getIsActiveByModuleName(user_permission, 'is_customer_archive_tab'),
    },
  ];

  const settingButtons = [
    {
      path: '/admin/routesetting',
      name: 'Route Setting',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Route Setting');
      },
      imgSrc: <RouteSettingsIcon />,
      active:
        tenant_permissions.is_route_tab &&
        genral_permissions.is_route_tab &&
        getIsActiveByModuleName(user_permission, 'is_route_tab'),
    },
    {
      path: '/admin/websetting',
      name: 'Web Setting',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Web Setting');
      },
      imgSrc: <WebSettingsIcon />,
      active:
        tenant_permissions.is_web_setting_tab &&
        genral_permissions.is_web_setting_tab &&
        getIsActiveByModuleName(user_permission, 'is_web_setting_tab'),
    },
    // {
    //   path: '/admin/customizationServiceLandingPage',
    //   name: 'Online Store',
    //   onClick: () => {
    //     setIsDrawerOpen(false);
    //     handleButtonClick('Customize SLP');
    //   },
    //   imgSrc: <SettingsIcon />,
    //   active:
    //     tenant_permissions.is_customize_slp_tab &&
    //     genral_permissions.is_customize_slp_tab &&
    //     getIsActiveByModuleName(user_permission, 'is_customize_slp_tab'),
    // },
  ];

  const foodButtons = [
    {
      path: '/admin/foodcategory',
      name: 'Meal Category',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Meal Category');
      },
      imgSrc: <FoodCategoryIcon />,
      active:
        tenant_permissions.is_food_category_tab &&
        genral_permissions.is_food_category_tab &&
        getIsActiveByModuleName(user_permission, 'is_food_category_tab'),
    },
    {
      path: '/admin/fooditems',
      name: 'Meal Items',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Meal Items');
      },
      imgSrc: <FoodItemsIcon />,
      active:
        tenant_permissions.is_food_item_tab &&
        genral_permissions.is_food_item_tab &&
        getIsActiveByModuleName(user_permission, 'is_food_item_tab'),
    },
    {
      path: '/admin/foodmeal',
      name: 'Meals',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Meal');
      },
      imgSrc: <FoodMealIcon />,
      active:
        tenant_permissions.is_meal_tab &&
        genral_permissions.is_meal_tab &&
        getIsActiveByModuleName(user_permission, 'is_meal_tab'),
    },

    {
      path: '/admin/adons',
      name: 'Add-Ons',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Add-Ons');
      },
      imgSrc: <FoodAddonIcon />,
      active:
        tenant_permissions.is_addon_tab &&
        genral_permissions.is_addon_tab &&
        getIsActiveByModuleName(user_permission, 'is_addon_tab'),
    },
    {
      path: '/admin/plancategory',
      name: 'Plan Category',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Plan Category');
      },
      imgSrc: <FoodPlanCategoryIcon />,
      active:
        tenant_permissions.is_plan_category_tab &&
        genral_permissions.is_plan_category_tab &&
        getIsActiveByModuleName(user_permission, 'is_plan_category_tab'),
    },
    {
      path: '/admin/menuplan',
      name: 'Menu Plans',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Menu Plan');
      },
      imgSrc: <FoodPlanIcon />,
      active:
        tenant_permissions.is_menu_plan_tab &&
        genral_permissions.is_menu_plan_tab &&
        getIsActiveByModuleName(user_permission, 'is_menu_plan_tab'),
    },
    {
      path: '/admin/mealplus',
      name: 'Meal +',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Meal +');
      },
      imgSrc: <MealIcon />,
      active:
        tenant_permissions.is_menu_plan_tab &&
        genral_permissions.is_menu_plan_tab &&
        getIsActiveByModuleName(user_permission, 'is_menu_plan_tab'),
    },
  ];

  const handleButtonClick = (route) => {
    // setSelectedButton(route);
    // const currentPathName = location.pathname;
    // setCurrentPath(currentPathName);
    // localStorage.setItem('selectedRoute', route);
  };

  useEffect(() => {
    const checkPathAndUpdateState = () => {
      const currentPathName = location.pathname;

      const isFoodMenuItems =
        foodButtons.filter(
          (button) =>
            button.path === currentPathName ||
            (currentPathName === '/admin/newmenuplan' &&
              button.path === '/admin/menuplan')
        ).length > 0;

      const isSettingItems =
        settingButtons.filter((button) => button.path === currentPathName)
          .length > 0;

      setShowFoodMenus(isFoodMenuItems);
      setShowSettingNavs(isSettingItems);
    };

    checkPathAndUpdateState();
    window.addEventListener('popstate', checkPathAndUpdateState);
    return () =>
      window.removeEventListener('popstate', checkPathAndUpdateState);
  }, [location.pathname]);

  // MN-Front
  const CustomDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
      position: 'inherit',
      width: '100%',
      background:
        'linear-gradient(rgb(241, 100, 91) 100%, rgb(241, 100, 91) 100%)',
      height: '100vh',
    },
  });

  return (
    <>
      <CustomDrawer
        variant={isLgUp ? 'persistent' : 'temporary'}
        open={isLgUp ? true : isDrawerOpen}
        // open={isLgUp ? true : isDrawerOpen}
        // onClose={() => setIsDrawerOpen(false)}
        sx={{
          overflow: 'hidden',
          width: isLgDown ? '260px !important' : null,
          '& .MuiPaper-root': {
            width: isLgDown ? '260px !important' : null,
          },
        }}
      >
        <List sx={drawerListStyles}>
          {isLgDown ? (
            <IconButton
              onClick={handleMainDrawerCloseToggle}
              sx={{
                position: 'absolute',
                right: '-4px',
                top: '-18px',
              }}
            >
              <Close />
            </IconButton>
          ) : (
            ''
          )}
          <SidebarLogo sideBarCollapse={hide} />
          <Divider
            sx={{
              width: '100%',
              height: '1px',
              background:
                'linear-gradient(270deg, rgba(255, 255, 255, 0.00) 3.07%, rgba(255, 255, 255, 0.50) 49.79%, rgba(255, 255, 255, 0.00) 96.75%)',
              border: 'none',
              mt: '15px',
            }}
          />
          <Box sx={{ mt: 3 }}>
            {buttons.map((button, index) => (
              <>
                {button.active ? (
                  <>
                    <SidebarItem
                      key={index}
                      selectedButton={selectedButton}
                      name={button.name}
                      path={button.path}
                      onClick={button.onClick}
                      src={button.imgSrc}
                      sideBarCollapse={hide}
                      parentLink={button.parentLink}
                    />
                    {button.type === 'FoodMenu' && showFoodMenus ? (
                      <>
                        <Box sx={{ ml: '15px' }}>
                          {foodButtons.map((foodbutton, index) => (
                            <>
                              {foodbutton.active ? (
                                <>
                                  <SidebarItem
                                    key={index}
                                    selectedButton={selectedButton}
                                    name={foodbutton.name}
                                    path={foodbutton.path}
                                    onClick={foodbutton.onClick}
                                    src={foodbutton.imgSrc}
                                    sideBarCollapse={hide}
                                    parentLink={false}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                    {button.type === 'Settings' && showSettingNavs ? (
                      <>
                        <Box sx={{ ml: '15px' }}>
                          {settingButtons.map((settingButton, index) => (
                            <>
                              {settingButton.active ? (
                                <SidebarItem
                                  key={index}
                                  selectedButton={selectedButton}
                                  name={settingButton.name}
                                  path={settingButton.path}
                                  onClick={settingButton.onClick}
                                  src={settingButton.imgSrc}
                                  sideBarCollapse={hide}
                                  parentLink={false}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ))}
          </Box>
        </List>
      </CustomDrawer>
    </>
  );
}

export default Sidebar;
