import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import TextFiel1 from '../TextField1/TextField1';

function TextFieldLight1({
  placeholder,
  label,
  type,
  backgroundColor = 'rgba(255, 250, 240, 1)',
  color = '#A5A7A6',
  border,
}) {
  return (
    <TextFiel1
      placeholder={placeholder}
      label={label}
      type={type}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
    />
  );
}

export default TextFieldLight1;
