import React, { useEffect, useState } from 'react';
import Notification from './notifications/notification';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { TENANT_URL } from './../../../../config';
import { useProfile } from '../../../customHooks/useProfile';
import Pagination from './paymentHistory/pagination';


const Notifications = ({}) => {
  const { notificationHistory, loading } = useProfile();
  const [pageNumber, setPageNumber] = useState(1);
  const [notificationsdat, setNotificationData] = useState([]);

  console.log("notificationHistory:",notificationHistory);
  return (
    <>
      <Box
        sx={{
          // display:'none',
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 10px 10px 10px',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
          borderRadius: '10px',
          boxShadow: '0px 0px 4px 0px #00000026',
          padding: '20px',
          height: { lg: '630px', xl: '710px', md: '1608px' },
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
          Notifications Sent
        </Typography>
        <Box
          sx={{
            overflowY: 'auto',
            height: '100%',
          }}
        >
          {notificationHistory.map((noti, index) => (
            <Notification key={index} data={noti.message} date={noti.created_at} />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          py: 0.5,
          marginTop: '15px',
        }}
      >
        <Pagination />
      </Box>
    </>
  );
};

export default Notifications;
