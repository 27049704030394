import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';

import EditProfile from './assets/editprofile.svg';
import Clock from './assets/clock.svg';
import Star from './assets/star.svg';
import ArrowRight from './assets/arrow-right.svg';
import ArrowLeft from './assets/arrow-left.svg';
import Cross from './assets/cross.svg';
import MealCross from './assets/mealscross.svg';

import '@fontsource/poppins';
import '@fontsource/poppins/500.css'; // Weight 500
import '@fontsource/poppins/600.css'; // Weight 600

// import { Grid, Button, Typography } from '@mui/material';
import { TENANT_URL } from '../../../config';
import axios from 'axios';
import { useProfile } from '../../../tenant/customHooks/useProfile';

const ProfileCard = ({ setOpen }) => {
  const {
    profileData,
    loading,
    getCustomerById,
    currentCustomer,
    updateCustomerActivation,
    updateCustomerSubscription,
  } = useProfile();
  const [checked, setChecked] = useState(profileData?.is_active);
  const [checkedSub, setCheckedSub] = useState(
    profileData?.subscription_status
  );
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const [profilesData, setProfilesData] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [date, setDate] = useState('');
  useEffect(() => {
    if (profileData) {
      // debugger;
      setDate(moment(profileData?.joining_date).utc().format('MMM DD, YYYY'));
      setChecked(profileData?.is_active);
      setCheckedSub(profileData?.subscription_status);
    }
  }, [date]);
  const handleStatus = (v, e) => {
    // debugger;
    if (e === 'activPause') {
      setChecked(v);
      updateCustomerActivation(v);
    } else {
      setCheckedSub(v);
      updateCustomerSubscription(v);
    }
  };

  useEffect(() => {
    const fetchCardsData = async () => {
      const config = {
        method: 'get',
        url: `${TENANT_URL}/customer/profile`,
        headers: {
          Authorization: `Bearer 174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(config);
        if (response.data) {
          setProfilesData(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    fetchCardsData();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Box
        className=""
        sx={{
          border: '1px solid #EBEBEC',
          borderRadius: '10px',
          padding: '20px',
          // maxWidth: '480px',
          // minWidth: '424px',
        }}
      >
        {loading ? (
          <></>
        ) : (
          <Box
            sx={{
              display: 'flex',
              p: { xs: '10px', sm: '15px' },
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
              borderRadius: '10px',
              overflow: 'hidden',
              // minWidth: { xs: '280px', sm: '380px' },
              // maxWidth: { xs: '320px', sm: '420px' },
              border: '1px solid #EBEBEC',
              margin: '0 auto 30px',
            }}
          >
            <Box
              sx={{
                mx: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  overflow: 'hidden',
                  width: { xs: '120px', sm: '150px' },
                  height: { xs: '90px', sm: '119px' },
                  position: 'relative',
                }}
              >
                <Button
                  sx={{
                    minWidth: '23px',
                    width: '23px',
                    padding: '2px',
                    position: 'absolute',
                    right: '0',
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => {
                    getCustomerById(currentCustomer);
                  }}
                >
                  <img src={EditProfile} />
                </Button>
                <Box
                  sx={{
                    overflow: 'hidden',
                    width: { xs: '90px', sm: '119px' },
                    height: { xs: '90px', sm: '119px' },
                    borderRadius: '100%',
                    objectFit: 'cover',
                  }}
                >
                  <img src={require('./assets/1.png')} width={'100%'} />
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: '14px', sm: '16px', xl: '17px' },
                  fontWeight: '600',
                  lineHeight: '21px',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {profilesData.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '10px', sm: '13px' },
                  fontWeight: '500',
                  lineHeight: '21px',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins',
                  textAlign: 'center',
                }}
              >
                {profilesData.email}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '10px', sm: '13px' },
                  fontWeight: '500',
                  lineHeight: '21px',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins',
                  textAlign: 'center',
                }}
              >
                {profilesData.phone}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '10px', sm: '13px' },
                  fontWeight: '500',
                  lineHeight: '21px',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins',
                  textAlign: 'center',
                  width: { xs: '100%', sm: '208px' },
                }}
              >
                {profilesData.address}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    gap: '12px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: '12px', sm: '14px' },
                      fontWeight: '400',
                    }}
                  >
                    {profileData?.email ? profileData?.email : ''}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '12px', sm: '14px' },
                      fontWeight: '400',
                    }}
                  >
                    {profileData?.phone ? profileData?.phone : ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {/* subscribe details */}
        <Box
          sx={{
            p: '15px',
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
            borderRadius: '10px',
            overflow: 'hidden',
            // minWidth: { xs: '100%', sm: '410px' },
            // maxWidth: { xs: '100%', sm: '420px' },
            border: '1px solid #EBEBEC',
            margin: '0 auto 30px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#3F2F5B',
              fontWeight: 600,
              fontFamily: 'Poppins',
              marginBottom: '15px',
              textTransform: 'uppercase',
              fontSize: { xs: '16px' }, // Adjusts font size for different breakpoints
            }}
          >
            Subscription Detail
          </Typography>

          <Box
            sx={{
              backgroundColor: '#F4F4F4',
              padding: 2,
              borderRadius: 2,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '15px',
              flexDirection: 'row', // Stacks items vertically on small screens
            }}
          >
            <div>
              <img src={Clock} />
            </div>
            <div style={{ textAlign: 'left' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: { xs: '10px', md: '11px' }, // Adjusts font size for different breakpoints
                  fontWeight: 400,
                  color: '#3F2F5B',
                }}
              >
                Join Date:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: '11px', md: '12px' }, // Adjusts font size for different breakpoints
                  fontWeight: 500,
                  color: '#3F2F5B',
                }}
              >
                {profilesData?.subscription_details?.start_date || 'N/A'}
              </Typography>
            </div>
          </Box>

          <Box
            sx={{
              backgroundColor: '#F4F4F4',
              padding: 2,
              borderRadius: 2,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '15px',
              flexDirection: 'row', // Stacks items vertically on small screens
            }}
          >
            <div>
              <img src={Clock} />
            </div>
            <div style={{ textAlign: 'left' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: { xs: '10px', md: '11px' }, // Adjusts font size for different breakpoints
                  fontWeight: 400,
                  color: '#3F2F5B',
                }}
              >
                Renewal Date:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: '11px', md: '12px' }, // Adjusts font size for different breakpoints
                  fontWeight: 500,
                  color: '#3F2F5B',
                }}
              >
                {profilesData?.subscription_details?.renewal_date || 'N/A'}
              </Typography>
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#F0645B',
                color: '#fff',
                borderRadius: 100,
                padding: '10px 16px',
                boxShadow: 'none',
                width: '100%',
                mb: { xs: 1, sm: 0 },
                height: '41px',
                '&:hover': {
                  backgroundColor: '#F0645B',
                },
                '@media (min-width: 1200px) and (max-width: 1400px)': {
                  padding: '10px 12px',
                  fontSize: '11px',
                },
                '@media (min-width: 1535px) and (max-width: 1600px)': {
                  padding: '10px 12px',
                  fontSize: '12px',
                },
              }}
            >
              Cancel Subscription
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: '#d32f2f',
                borderColor: '#d32f2f',
                borderRadius: 100,
                padding: '10px 16px',
                boxShadow: 'none',
                width: '100%',
                height: '41px',
                '&:hover': {
                  backgroundColor: '#F0645B',
                  borderColor: '#F0645B',
                  color: '#fff',
                },
                '@media (min-width: 1200px) and (max-width: 1400px)': {
                  padding: '10px 12px',
                  fontSize: '11px',
                },
                '@media (min-width: 1535px) and (max-width: 1600px)': {
                  padding: '10px 12px',
                  fontSize: '12px',
                },
              }}
            >
              Pause Subscription
            </Button>
          </Box>
        </Box>
        {/* meal  */}
        <Box
          sx={{
            p: '15px',
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
            borderRadius: '10px',
            overflow: 'hidden',
            // minWidth: { xs: '100%', sm: '410px' },
            // maxWidth: { xs: '100%', sm: '420px' },
            border: '1px solid #EBEBEC',
            margin: '0 auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex align-items-center gap-2">
              <Typography
                variant="h6"
                sx={{
                  color: '#3F2F5B',
                  fontWeight: 600,
                  fontFamily: 'Poppins',
                  marginBottom: '0',
                  fontSize: { xs: '16px', md: '20px' }, // Adjusts font size for different breakpoints
                }}
              >
                MEAL +
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <img src={ArrowLeft} />
                <img src={ArrowRight} />
              </Box>
            </div>
            <div className="">
              <img src={Star} />
            </div>
          </div>

          <Box
            sx={{
              backgroundColor: '#F4F4F4',
              padding: 2,
              borderRadius: 2,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '15px',
              flexDirection: { xs: 'column', sm: 'row' }, // Stacks items vertically on small screens
              position: 'relative',
            }}
          >
            <div>
              <img src={Clock} />
            </div>
            <Box
              sx={{
                textAlign: { xs: 'center', sm: 'left' },
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: { xs: '10px', md: '11px' }, // Adjusts font size for different breakpoints
                  fontWeight: 400,
                  color: '#3F2F5B',
                }}
              >
                Available For:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: '11px', md: '12px' }, // Adjusts font size for different breakpoints
                  fontWeight: 500,
                  color: '#3F2F5B',
                }}
              >
                Mon 12, 2024 - Mon 18, 2024
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: { xs: '9px', md: '12px' }, // Adjusts font size for different breakpoints
                fontWeight: 400,
                color: '#3F2F5B',
                position: 'absolute',
                right: '7px',
                top: '15px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={handleOpenModal}
            >
              Customize
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#F4F4F4',
              padding: 2,
              borderRadius: 2,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '15px',
              flexDirection: { xs: 'column', sm: 'row' }, // Stacks items vertically on small screens
              position: 'relative',
            }}
          >
            <div>
              <img src={Clock} />
            </div>
            <Box
              sx={{
                textAlign: { xs: 'center', sm: 'left' },
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: { xs: '10px', md: '11px' }, // Adjusts font size for different breakpoints
                  fontWeight: 400,
                  color: '#3F2F5B',
                }}
              >
                Available For:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: '11px', md: '12px' }, // Adjusts font size for different breakpoints
                  fontWeight: 500,
                  color: '#3F2F5B',
                }}
              >
                Wed 12, 2024
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: { xs: '9px', md: '12px' }, // Adjusts font size for different breakpoints
                fontWeight: 400,
                color: '#3F2F5B',
                position: 'absolute',
                right: '7px',
                top: '15px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={handleOpenModal}
            >
              Customize
            </Typography>
          </Box>
        </Box>
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '750px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '30px 30px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Replace Meals
            </Typography>
            <img src={Cross} alt="Close" onClick={handleCloseModal} />
          </div>
          {days.map((day, index) => (
            <Box key={index} sx={{ marginBottom: 3 }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  marginBottom: '6px',
                  fontFamily: 'Poppins',
                  color: '#3F2F5B',
                  fontSize: '14px',
                }}
              >
                {day}
              </Typography>

              <Box
                sx={{
                  display: { xs: 'block', md: 'flex' },
                  alignItems: 'end',
                  gap: 2,
                }}
              >
                <div className="selctmeal">
                  <select
                    style={{
                      padding: '5px 10px',
                      paddingRight: '30px',
                      borderRadius: '10px',
                      border: '1px solid #EBEBEC',
                      flex: 1,
                      width: '100%',
                      fontSize: '13px',
                      color: '#4F4D55',
                      fontFamily: 'Poppins',
                      height: '44px',
                      appearance: 'none',
                      background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none"><path d="M13 1L7 7L1 1" stroke="%237E7E7E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat right 10px center`,
                      backgroundColor: 'white',
                    }}
                  >
                    <option value="Rajma Rice">Rajma Rice</option>
                  </select>
                </div>
                <div className="d-flex align-items-center gap-2 selectmealbtn">
                  {[...Array(3)].map((_, btnIndex) => (
                    <Button
                      key={btnIndex}
                      variant="contained"
                      color="error"
                      sx={{
                        fontSize: '12px',
                        backgroundColor: '#F0645B',
                        color: '#fff',
                        borderRadius: 100,
                        padding: '8px 12px',
                        boxShadow: 'none',
                        width: { xs: '90px', sm: '120px', md: '150px' },
                        height: '35px',
                        '@media (max-width: 900px)': {
                          padding: '8px 12px',
                          fontSize: '10px',
                        },
                      }}
                    >
                      Meal Name Here{' '}
                      <img
                        src={MealCross}
                        alt="Remove"
                        style={{ paddingLeft: '3px' }}
                      />
                    </Button>
                  ))}
                </div>
              </Box>
            </Box>
          ))}
          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#F0645B',
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileCard;
