import React, { useState } from 'react';
import StyledBox from './StyledBox';
import FontTabs from './../../../styles/FontStyles/FontTabs';
import CardsTabs from '../../../styles/cardStyles/CardsStyleTabs/CardTabs';
import CardsLayoutTabs from '../../../styles/cardLayouts/CardsLayoutTabs/LayoutTabs';
import ButtonStylesTabs from '../../../styles/ButtonStyles/ButtonStylesTabs/ButtonStylesTabs';
import { Box } from '@mui/material';
import LandingPagesTabs from '../../../styles/LandingPages/LandingPagesTabs/LandingPagesTabs';
import NavFooterTabs from '../../../styles/NavbarAndFooterTabs/NavFooterTabs';
import TrialSection from '../../../styles/LandingPages/TrialSection/TrialSection';

const SelectionBox = ({ activeTab }) => {
  return (
    <StyledBox>
      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'absolute',
          top: '0',
          zoom: 0.84,
          transformOrigin: 'top left',
          overflow: 'auto',
          height: 'calc(100% - 60px)',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            background:
              'linear-gradient(95.07deg, #F77252 3.98%, #E05750 80.2%)',
            width: '4px',
          },
        }}
      >
        {activeTab === 0 && <LandingPagesTabs />}
        {activeTab === 1 && <CardsLayoutTabs />}
        {activeTab === 2 && <CardsTabs />}
        {activeTab === 3 && <ButtonStylesTabs />}
        {activeTab === 4 && <FontTabs />}
        {activeTab === 5 && <NavFooterTabs />}
        {activeTab === 6 && <TrialSection />}
      </Box>
    </StyledBox>
  );
};

export default SelectionBox;
