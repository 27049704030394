import moment from 'moment';

const tspColumns = [
  {
    field: 'created_at',
    headerName: 'Payment Date',
    flex: 1,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',

    renderCell: (params) => (
      <>
        <p
          style={{
            fontFamily: 'Outfit',
          }}
        >
          {moment(params.row.created_at).utc().format('DD-MM-YYYY')}
        </p>
      </>
    ),
  },
  {
    field: 'medium',
    headerName: 'Payment Medium',
    flex: 1,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <p
          style={{
            fontFamily: 'Outfit',
          }}
        >
          {params.row.medium}
        </p>
      </>
    ),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'right',
    align: 'right',

    renderCell: (params) => (
      <>
        <p
          style={{
            fontFamily: 'Outfit',
          }}
        >
          {params.row.amount}CR
        </p>
      </>
    ),
  },
];

export default tspColumns;
