import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TENANT_URL } from '../../../config';
import Datacard from '../../../tenant/pages/profile/components/cards/dataCard';

const DataCards = () => {
  const [cardsData, setCardsData] = useState({});
  const [statsData, setStatsData] = useState({});

  // Fetch data on component mount
  useEffect(() => {
    const fetchDashboardData = async () => {
      const tenantData = JSON.parse(localStorage.getItem('tenantdata'));

      const authToken =
        // tenantData?.token || '171|UCAHsmDrFoi8IifEo4LZxBZZAUJmHWhfstvDxuqMc0e3a0f0';
        '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';

      const endpoints = [
        {
          url: `${TENANT_URL}/customer/app/dashboard`,
          setData: setCardsData,
        },
        {
          url: `${TENANT_URL}/customer/app/stats`,
          setData: setStatsData,
        },
      ];

      try {
        await Promise.all(
          endpoints.map(async ({ url, setData }) => {
            const { data } = await axios.get(url, {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
              },
            });
            setData(data.data || data);
          })
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDashboardData();
  }, []); // Empty dependency array ensures it runs only on mount

  const cardsDataTemplate = [
    {
      message: 'Orders',
      image: 'order',
      carddata: statsData?.order_count || '0',
    },
    {
      message: 'Subscription Plan',
      image: 'biweekly',
      // carddata: cardsData?.subscription_plan || 'Biweekly',
      carddata: 'Biweekly',
    },
    {
      message: `${cardsData?.delivered || 0} delivered / ${
        cardsData?.remaining || 0
      } remaining`,
      image: 'currentplan',
      // carddata: cardsData?.plan_name || 'Current Plan',
      carddata: 'Current Plan',
    },
    {
      message: 'In Wallet',
      image: 'walletcustomer',
      carddata: statsData?.wallet_amount || '$0',
    },
    // {
    //   message: 'Remaining Meals',
    //   image: 'renewal',
    //   carddata: cardsData?.remaining || '0',
    // },
    {
      message: 'Messages Left',
      image: 'messageleft',
      // carddata: cardsData?.plan_category_name || '100',
      carddata: '100',
    },
    {
      message: 'Subscription Plan',
      image: 'goldplan',
      // carddata: cardsData?.plan_category_name || 'Gold Plan',
      carddata: 'Gold Plan',
    },
  ];

  return (
    <Grid container spacing={'30px'}>
      {cardsDataTemplate.map((card, index) => (
        <Grid item xs={12} sm={12} lg={6} xl={4} key={index}>
          <Datacard
            message={card.message}
            image={card.image}
            data={card.carddata}
            index={index}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DataCards;
