import { Box, Typography } from '@mui/material';
import React from 'react';
import LineSvg from '../../assets/icons/Line.svg';
import { Padding } from '@mui/icons-material';

function Menu5() {
  const typStyle = {
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000',
    fontFamily: 'Inter !important',
  };
  const timeStyle = {
    color: '#4A4A4A',
    fontFamily: 'Inter !important',
    fontSize: '16px',
    fontWeight: 400,
  };

  const boxStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'space-between',
    padding: '12px',
    backgroundColor: '#ffffff',
    marginBottom: '10px',
    borderRadius: '15px',
  };

  return (
    <Box
      sx={{
        background: '#FCFCFC',
        boxShadow: '0 0px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '15px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          gap: '30px',
          backgroundColor: '#FCFCFC',
          borderRadius: '15px',
          width: '100%',
        }}
      >
        <Box sx={boxStyle}>
          <Typography sx={typStyle}>Monday</Typography>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}>Tuesday</Typography>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}>Wednesday</Typography>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}>Thursday</Typography>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}>Friday</Typography>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}>Saturday</Typography>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
        </Box>
        <Box sx={{ ...boxStyle, marginBottom: 0 }}>
          <Typography sx={typStyle}>Sunday</Typography>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Menu5;
