import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Grid,
  Typography,
  Modal,
  Menu,
  Switch,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/utils/spinner';
import Cross from '../../../tenant/pages/profile/assets/cross.svg';
import TrialMealCards from './components/cards';
import { DataGrid } from '@mui/x-data-grid';
import Pagination from './components/paginations';
import SearchTextField from '../../../customer/components/SearchTextField';
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormModalInputText from './components/formfied/inputfield';
import FormTextarea from './components/formfied/texreafiels';
import { TENANT_URL } from './../../../config';

import axios from 'axios';

const MealPlus = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [editId, setEditID] = useState(0);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const handleResetButton = () => {
    setName("");
    setPrice("");
    setDescription("");
    setStatus("");
  }

  // Function to handle search
  const handleSearchChange = async (event) => {
    setSearchKeyword(event.target.value);
    console.log("searchKeyword:",searchKeyword);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/trial/food/item?search=${event.target.value}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setRows(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Filter rows based on search keyword
  const filteredRows = rows.filter((row) =>
    row.name.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const handleSubmit = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    if (!name || !price || !description) {
      setErrorMessage('Please enter all fields');
      return;
    }

    const data = {
      name: name,
      description: description,
      price: price,
      is_active: isActive,
    };

    const addConfig = {
      method: !!editId ? 'put' : 'post',
      url: !!editId
        ? `${TENANT_URL}/trial/food/item/${editId}`
        : `${TENANT_URL}/trial/food/item`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios({ ...addConfig, data });
      if (response.status == '201' || response.status == '200') {
        setName('');
        setPrice('');
        setDescription('');
        window.location.reload();
      } else {
        setErrorMessage(
          response.data.message || 'Some error occur. Please try again.'
        );
      }
    } catch (error) {
      console.error(error);
    }

    handleCloseModel();
  };
  const deleteTrial = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    if (!editId) {
      setErrorMessage('Please enter all fields');
      return;
    }

    const addConfig = {
      method: 'delete',
      url: `${TENANT_URL}/trial/food/item/${editId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios({ ...addConfig });
      console.log('response:', response);
      if (response.status == '201' || response.status == '200') {
        window.location.reload();
      } else {
        setErrorMessage(
          response.data.message || 'Some error occur. Please try again.'
        );
      }
    } catch (error) {
      console.error(error);
    }

    handleCloseModel();
  };

  // Add Modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setEditID(0);
    setName('');
    setPrice('');
    setDescription('');
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenModal1(false);
  };

  // Edit Modal
  const [openModal1, setOpenModal1] = useState(false);
  const handleOpenModal1 = (trial) => {
    setEditID(trial.row.id);
    setName(trial.row.name);
    setPrice(trial.row.price);
    setDescription(trial.row.description);
    setStatus(trial.row.is_active);
    setOpenModal1(true);
  };
  const handleCloseModel = (id) => {
    setOpenModal(false);
    setOpenModal1(false);
    setOpenModal2(false);
  };

  // Delete Modal
  const [openModal2, setOpenModal2] = useState(false);
  const handleOpenModal2 = (trial) => {
    setEditID(trial.row.id);
    setOpenModal2(true);
  };

  //
  const customerColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px' }}>{params.value}</div>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created on',
      flex: 1,
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px' }}>{params.value}</div>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      headerAlign: 'left',
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px' }}>{params.value}</div>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      headerClassName: 'header-cell',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', textAlign: 'left' }}>
          ${params.value}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 5,
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <>
          <div
            style={{
              backgroundColor: '#54BA4A',
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              marginRight: '5px',
            }}
          />
          {params.value}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 80,
      headerClassName: 'header-cell',
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'column-cell',
      renderCell: (params) => {
        return (
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="filled"
              onClick={handleClick}
              sx={{
                textTransform: 'capitalize',
                padding: 0, // Remove any extra padding
                minWidth: 88, // Fixed width for the circle
                height: '40px', // Fixed height for the circle
                borderRadius: 1000, // Circle shape
                fontWeight: 400,
                color: 'white',
                boxShadow: 'none',
                '&:focus': {
                  outline: 0,
                },
              }}
            >
              Actions
            </Button>

            <Box ref={menuRef}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    minWidth: 100,
                    marginTop: '8px',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.04)', // Add shadow here
                    borderRadius: '10px',
                  },

                  '& .MuiMenuItem-root': {
                    padding: '5px 16px',
                    color: '#7E6E9A',
                    fontSize: '12px',
                    fontWeight: 500,
                    width: '100%',
                  },
                }}
              >
                <MenuItem sx={{ width: '100%', my: 0.1, fontSize: '12px' }}>
                  View
                </MenuItem>
                <MenuItem
                  onClick={() => handleOpenModal1(params)}
                  sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
                >
                  Edit
                </MenuItem>

                <MenuItem
                  onClick={() => handleOpenModal2(params)}
                  sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </div>
        );
      },
    },
  ];

  const getTiralItems = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/trial/food/item`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setRows(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTiralItems();
  }, []);

  const menuRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {false ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <TrialMealCards rows={rows} />
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              borderRadius: '15px',
              boxShadow: '0px 4px 4px 0px #00000026',
              overflowY: 'auto',
              padding: '15px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <input
                type="text"
                value={searchKeyword} // Connect state to input
                onChange={handleSearchChange} // Update state on input change
                style={{
                  padding: '8px', // Add padding for a better look
                  borderRadius: '4px', // Optional rounded corners
                  border: '1px solid #ccc', // Optional border styling
                  fontSize: '14px', // Optional font size
                  // ...newStyle, // Merge user-provided styles
                }}
              />
              <Button
                variant="filled"
                onClick={handleOpenModal}
                sx={{
                  textTransform: 'capitalize',
                  padding: 0, // Remove any extra padding
                  minWidth: 100, // Fixed width for the circle
                  height: '40px', // Fixed height for the circle
                  borderRadius: 1000, // Circle shape
                  fontWeight: 400,
                  color: 'white',
                  boxShadow: 'none',
                  '&:focus': {
                    outline: 0,
                  },
                }}
              >
                + Add New
              </Button>
            </Box>
            <Grid
              container
              sx={{
                marginTop: '14px',
              }}
            >
              <DataGrid
                sx={{
                  width: '100%',
                  border: 'none',
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& .MuiDataGrid-withBorderColor': {
                    border: 'none',
                  },
                  '& .column-cell': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    borderBottom: '1px solid white',
                  },
                  '& .header-cell': {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    width: '100%',

                    borderBottom: '3px solid white',
                  },
                  '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'normal',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '--unstable_DataGrid-radius': 'none',
                  borderTop: '1px solid white',
                }}
                rows={rows}
                columns={customerColumns}
                hideFooter
                disableColumnFilter
                // checkboxSelection
                disableColumnSelector

                // autoPageSize={true}
              />
            </Grid>
          </Box>
          <Box>
            <Pagination />
          </Box>
        </>
      )}

      {/* Add Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '767px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Add Trial Meal
            </Typography>
            <img
              src={Cross}
              style={{
                cursor: 'pointer',
              }}
              alt="Close"
              onClick={handleCloseModal}
            />
          </div>

          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: '15px',
            }}
          >
            <Grid item xs={12} sm={6}>
              <input
                label={'Meal Name'}
                placeholder={'Enter Meal Name'}
                type={'text'}
                value={name}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '10px',
                  border: '1px solid #ccc',
                  fontSize: '16px',
                  fontFamily: 'Arial, sans-serif',
                  resize: 'vertical', // Allows resizing only vertically
                }}
                onChange={(e) => {
                  console.log('Name input changed:', e.target.value); // Debugging
                  setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                label={'Meal Price'}
                placeholder={'Enter Meal Price'}
                type={'number'}
                value={price}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '10px',
                  border: '1px solid #ccc',
                  fontSize: '16px',
                  fontFamily: 'Arial, sans-serif',
                  resize: 'vertical', // Allows resizing only vertically
                }}
                onChange={(e) => {
                  console.log('Name input changed:', e.target.value); // Debugging
                  setPrice(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <textarea
            label={'Meal Description'}
            placeholder={'Enter Meal Description'}
            type={'textarea'}
            value={description}
            style={{
              width: '100%',
              height: '150px',
              borderRadius: '8px',
              padding: '10px',
              border: '1px solid #ccc',
              fontSize: '16px',
              fontFamily: 'Arial, sans-serif',
              resize: 'vertical', // Allows resizing only vertically
            }}
            onChange={(e) => {
              console.log('Name input changed:', e.target.value); // Debugging
              setDescription(e.target.value);
            }}
          />
          {errorMessage}
          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Box
              sx={{
                marginRight: 'auto',
              }}
            >
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Status"
                labelPlacement="start"
                sx={{
                  marginLeft: '0px',
                }}
                checked={isActive}
                onChange={(e) => setStatus(e.target.checked)} // Inline handler
              />
            </Box>
            <Button
              variant="text"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '3px',
              }}
            >
              <UploadIcon />
              <Typography
                variant="h6"
                sx={{
                  marginBottom: '0',
                  fontSize: '14px',
                  fontFamily: 'Outfit',
                  fontWeight: '500',
                  color: '#AC9EC3',
                  textDecoration: 'underline',
                }}
              >
                Upload Image
              </Typography>
            </Button>
            <Button
              onClick={handleResetButton}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#AC9EC3',
                color: '#AC9EC3',
              }}
            >
              Reset
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
                color: '#ffffff',
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal open={openModal1} onClose={handleCloseModel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '767px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Edit Trial Meal
            </Typography>
            <img
              src={Cross}
              style={{
                cursor: 'pointer',
              }}
              alt="Close"
              onClick={handleCloseModal}
            />
          </div>

          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: '15px',
            }}
          >
            <Grid item xs={12} sm={6}>
              <input
                label={'Meal Name'}
                placeholder={'Enter Meal Name'}
                type={'text'}
                value={name}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '10px',
                  border: '1px solid #ccc',
                  fontSize: '16px',
                  fontFamily: 'Arial, sans-serif',
                  resize: 'vertical', // Allows resizing only vertically
                }}
                onChange={(e) => {
                  console.log('Name input changed:', e.target.value); // Debugging
                  setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                label={'Meal Price'}
                placeholder={'Enter Meal Price'}
                type={'text'}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '10px',
                  border: '1px solid #ccc',
                  fontSize: '16px',
                  fontFamily: 'Arial, sans-serif',
                  resize: 'vertical', // Allows resizing only vertically
                }}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Grid>
          </Grid>
          <textarea
            label={'Meal Name'}
            placeholder={'Enter Meal Name'}
            type={'textarea'}
            value={description}
            style={{
              width: '100%',
              height: '150px',
              borderRadius: '8px',
              padding: '10px',
              border: '1px solid #ccc',
              fontSize: '16px',
              fontFamily: 'Arial, sans-serif',
              resize: 'vertical', // Allows resizing only vertically
            }}
            onChange={(e) => setDescription(e.target.value)}
          />
          {errorMessage}
          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Box
              sx={{
                marginRight: 'auto',
              }}
            >
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Status"
                labelPlacement="start"
                sx={{
                  marginLeft: '0px',
                }}
                checked={isActive}
                onChange={(e) => setStatus(e.target.checked)} // Inline handler
              />
            </Box>
            <Button
              variant="text"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '3px',
              }}
            >
              <UploadIcon />
              <Typography
                variant="h6"
                sx={{
                  marginBottom: '0',
                  fontSize: '14px',
                  fontFamily: 'Outfit',
                  fontWeight: '500',
                  color: '#AC9EC3',
                  textDecoration: 'underline',
                }}
              >
                Upload Image
              </Typography>
            </Button>
            <Button
              onClick={handleResetButton}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#AC9EC3',
                color: '#AC9EC3',
              }}
            >
              Reset
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
                color: '#ffffff',
              }}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Modal */}
      <Modal open={openModal2} onClose={handleCloseModel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '385px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Are You Sure?
            </Typography>
            <img src={Cross} alt="Close" onClick={handleCloseModal} />
          </div>

          <Typography
            variant="h6"
            sx={{
              marginBottom: '0',
              fontSize: '14px',
              fontFamily: 'Outfit',
              fontWeight: '400',
              color: '#4F4D55',
            }}
          >
            You want to delete trial meal plan?
          </Typography>

          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Button
              onClick={handleCloseModel}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#F0645B',
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
              }}
              onClick={deleteTrial}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MealPlus;
