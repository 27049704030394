import React, { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { drawerListStyles } from './config';
import SidebarLogo from './SidebarLogo/SidebarLogo';
import SidebarItem from './SidebarItem/SidebarItem';
import { ReactComponent as DashboardIcon } from '../../../icons/dashboardIcon.svg';
import { ReactComponent as TSPIcon } from '../../../icons/tsp_icon.svg';
import { ReactComponent as SubscriptionIcon } from '../../../icons/subscription_plan.svg';
import { ReactComponent as EmailsIcon } from '../../../icons/email_icon.svg';
import { ReactComponent as MessagesIcon } from '../../../icons/messages_icon.svg';
import { ReactComponent as ReportsIcon } from '../../../icons/reports_icon.svg';
import { ReactComponent as TrailsIcon } from '../../../icons/trail_icon.svg';
import { ReactComponent as EarningsIcon } from '../../../icons/earnings_icon.svg';
import { ReactComponent as SettingsIcon } from '../../../icons/settings_icon.svg';

function Sidebar({ leftSpan, showHoverSidebar,setShowHoverSidebar,setSearchVal }) {
  const [selectedButton, setSelectedButton] = useState('Dashboard');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const buttons = [
    {
      path: '/customer/profile',
      name: 'Profile',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Profile');
      },
      imgSrc: <DashboardIcon style={{ fill: 'white' }} />,
    },
    {
      path: '/customer/subscription',
      name: 'Subscriptions',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Subscriptions');
      },
      imgSrc: <TSPIcon />,
    },
    {
      path: '/customer/orderhistory',
      name: 'Order History',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Order History');
      },
      imgSrc: <SubscriptionIcon />,
    },
    {
      path: '/customer/paymenthistory',
      name: 'Payment History',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Payment History');
      },
      imgSrc: <EarningsIcon />,
    },
  ];

  const handleButtonClick = (route) => {
    setShowHoverSidebar(true)
    setSelectedButton(route);
    setSearchVal("")
    localStorage.setItem('selectedRoute', route);
  };

  useEffect(() => {
    const storedRoute = localStorage.getItem('selectedRoute');
    if (storedRoute) {
      setSelectedButton(storedRoute);
    }
  }, []);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const CustomDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
      background: 'linear-gradient(rgb(241, 100, 91) 100%, rgb(241, 100, 91) 100%)',
      width: '273px',
    },
  });

  return (
    <>
      {!isMdUp && (
        <IconButton onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      )}
      <CustomDrawer
        variant={isMdUp ? 'persistent' : 'temporary'}
        open={isMdUp ? true : isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <List sx={drawerListStyles}>
          <SidebarLogo />
          <Box sx={{ mt: 5 }}>
            {buttons.map((button) => (
              <SidebarItem
                key={button.name}
                selectedButton={selectedButton}
                name={button.name}
                path={button.path}
                onClick={button.onClick}
                src={button.imgSrc}
                showHoverSidebar={showHoverSidebar}
              />
            ))}
          </Box>
        </List>
      </CustomDrawer>
    </>
  );
}

export default Sidebar;
