import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';

function Textarea({ label, placeholder, value }) {
  return (
    <Box>
      <Typography
        sx={{
          color: '#3B4A45',
          fontSize: '16px',
          fontWeight: '500',
        }}
      >
        {label}
      </Typography>
      <TextField
        variant="standard"
        sx={{ marginTop: '5px', border: 'none', outline: 'none' }}
        fullWidth
        placeholder={placeholder}
        InputProps={{
          disableUnderline: true,
          startAdornment: <InputAdornment position="start"></InputAdornment>,
          sx: {
            borderRadius: '10px',
            color: '#A5A7A6',
            bgcolor: '#ffffff',
            border: '1px solid #000000',
            padding: '12px',
            fontFamily: 'Inter !important',
            '& .MuiInputBase-input::placeholder': {
              color: '#000000', // Placeholder color
              fontFamily: 'Inter !important',
            },
          },
        }}
        multiline
        rows={4}
      />
    </Box>
  );
}

export default Textarea;
