import React from 'react';
import { Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


const NavbarSearch = ({ sx,setSearchVal,searchVal }) => {
  const handleSearch = (e) =>{
    setSearchVal(e.target.value)

  }
  return (
    <Box sx={{ position: 'relative', ...sx }}>
    <input
      type="text"
      value={searchVal}
      onChange={handleSearch}
      style={{
        border: '1px solid #EBEBEC',
        fontSize: '14px',
        padding: '8px 40px',
        borderRadius: '20px',
        width: '100%',
      }}
      placeholder="Search anything here......."
    />
    <SearchIcon
      sx={{
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translateY(-50%)',
        fontSize: '20px',
        color: '#6C757D',
        
      }}
    />
  </Box>
  );
};

export default NavbarSearch;
