import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Dialog, Button } from "@mui/material";
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import { TENANT_URL } from '../../../../config';
import RightTick from './../assets/righttick.svg';
import axios from 'axios';

const MealCalendar = () => {
  const [currentMonthIndex, setCurrentMonthIndex] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMealData, setSelectedMealData] = useState(null);
  const [cardsData, setCardsData] = useState({});
  const [monthlyMeals, setMonthlyMeals] = useState({});

  useEffect(() => {
    const fetchCardsData = async () => {
      const config = {
        method: 'get',
        url: `${TENANT_URL}/customer/app/plan/detials`,
        headers: {
          Authorization: `Bearer 174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(config);
        if (response.data) {
          setCardsData(response.data.data);
          // Organize meals by date
          const mealsByDate = {};
          response.data.data.monthly_plan.forEach(plan => {
            const date = new Date(plan.plan_date);
            const dateKey = date.toISOString().split('T')[0];
            mealsByDate[dateKey] = plan;
          });
          setMonthlyMeals(mealsByDate);
        }
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCardsData();
  }, []);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const handlePrevMonth = () => {
    setCurrentMonthIndex(prev => {
      if (prev === 0) {
        setCurrentYear(year => year - 1);
        return 11;
      }
      return prev - 1;
    });
  };

  const handleNextMonth = () => {
    setCurrentMonthIndex(prev => {
      if (prev === 11) {
        setCurrentYear(year => year + 1);
        return 0;
      }
      return prev + 1;
    });
  };

  const generateMonthData = (month, year) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDay = new Date(year, month, 1).getDay();
    
    return {
      days: daysInMonth,
      firstDay: firstDay,
      events: Array.from({ length: daysInMonth }, (_, i) => {
        const currentDate = new Date(year, month, i + 1);
        const dateKey = currentDate.toISOString().split('T')[0];
        const mealData = monthlyMeals[dateKey];
        
        return {
          date: i + 1,
          meal: mealData ? mealData.food_meal_name.join(', ') : "",
          mealData: mealData,
          status: mealData ? 'completed' : 'pending'
        };
      })
    };
  };

  const handleViewAll = (date, mealData) => {
    setSelectedDate(date);
    setSelectedMealData(mealData);
    setIsModalOpen(true);
  };

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const renderCalendarGrid = () => {
    const monthData = generateMonthData(currentMonthIndex, currentYear);
    const blanks = Array(monthData.firstDay).fill(null);
    const daysArray = monthData.events;

    return (
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(7, 1fr)', 
        gap: 0,
        border: '1px solid #EBEBEC',
        borderRadius: '10px',
        padding: '22px 15px',
        '@media (max-width: 1535px)': {
          overflowX: 'scroll',
        },
      }}>
        {weekDays.map((day) => (
          <Box key={day} sx={{
            textAlign: 'center',
            color: '#3F2F5B',
            fontWeight: 500,
            fontSize: '10px',
            fontFamily: 'Poppins',
          }}>
            {day}
          </Box>
        ))}

        {blanks.map((_, index) => (
          <Box key={`blank-${index}`} />
        ))}

        {daysArray.map((event, index) => (
          <Box
            key={`day-${index}`}
            sx={{
              p: 1,
              m: 1,
              border: '1px solid',
              borderColor: 'rgb(229, 229, 229)',
              borderRadius: '8px',
              borderLeft: event.mealData ? '2px solid rgb(242, 80, 64)' : '1px solid rgb(229, 229, 229)',
              backgroundColor: '#fff',
              minHeight: '100px',
              '@media (max-width: 1535px)': {
                width: '105px'
              },
              '@media (min-width: 1535px) and (max-width:1700px)': {
                width: '95px'
              },
              '@media (min-width: 1701px) and (max-width:1850px)': {
                width: '105px'
              },
              '@media (min-width: 1851px) and (max-width:2200px)': {
                width: '120px',
                marginTop: '30px'
              },
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 1 
            }}>
              <Typography sx={{ 
                fontSize: '12px',
                fontWeight: 600,
                color: '#3F2F5B',
                fontFamily: 'Poppins'
              }}>
                {`${months[currentMonthIndex].substring(0, 3)} ${String(event.date).padStart(2, '0')}`}
              </Typography>
              {event.mealData && (
                <Box sx={{
                  width: 20,
                  height: 20,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Box component="span" sx={{ color: '#fff', fontSize: '0.75rem' }}>       
                  <img src={RightTick} />
                  </Box>
                </Box>
              )}
            </Box>
            {event.mealData && (
              <>
                <Typography sx={{ 
                  fontSize: '0.75rem',
                  color: 'rgb(107, 114, 128)',
                  mb: 1,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                  {event.meal}
                </Typography>
                <Button
                  onClick={() => handleViewAll(event.date, event.mealData)}
                  sx={{
                    color: '#3F2F5B',
                    textTransform: 'none',
                    p: 0,
                    minWidth: 'auto',
                    fontSize: '10px',
                    fontWeight: 600,
                    fontFamily: 'Poppins',
                    textDecoration: 'underline',
                    height: '0',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      textDecoration: 'underline',
                    }
                  }}
                >
                  View All
                </Button>
              </>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ backgroundColor: '#fff' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Typography sx={{ 
          fontSize: '18px',
          fontWeight: 600,
          color: '#3F2F5B',
          fontFamily: 'Poppins'
        }}>
          {`${months[currentMonthIndex]} ${currentYear}`}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton 
            onClick={handlePrevMonth}
            sx={{ 
              border: '1px solid #9E9E9E',
              borderRadius: '50%',
              width: 40,
              height: 40
            }}
          >
            <ChevronLeft size={20} />
          </IconButton>
          <IconButton 
            onClick={handleNextMonth}
            sx={{ 
              border: '1px solid #9E9E9E',
              borderRadius: '50%',
              width: 40,
              height: 40
            }}
          >
            <ChevronRight size={20} />
          </IconButton>
        </Box>
      </Box>

      {renderCalendarGrid()}

      <Dialog 
        open={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '16px',
            p: 3
          }
        }}
      >
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}>
          <Typography sx={{ 
            fontSize: '1.25rem',
            fontWeight: 500,
            color: '#3F2F5B'
          }}>
            {selectedDate && `${months[currentMonthIndex]} ${selectedDate}, ${currentYear}`}
          </Typography>
          <IconButton 
            onClick={() => setIsModalOpen(false)}
            sx={{ color: 'rgb(242, 80, 64)' }}
          >
            <X size={24} />
          </IconButton>
        </Box>

        {selectedMealData && (
          <>
            <Box sx={{ mb: 3 }}>
              <Typography sx={{ 
                fontSize: '1.125rem',
                fontWeight: 500,
                color: '#3F2F5B',
                mb: 1
              }}>
                Meal Items
              </Typography>
              <Typography sx={{ color: 'rgb(107, 114, 128)' }}>
                {selectedMealData.food_meal_name.join(', ')}
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography sx={{ 
                fontSize: '1.125rem',
                fontWeight: 500,
                color: '#3F2F5B',
                mb: 1
              }}>
                Addon
              </Typography>
              <Typography sx={{ color: 'rgb(107, 114, 128)' }}>
                {selectedMealData.addons_name.join(', ')}
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography sx={{ 
                fontSize: '1.125rem',
                fontWeight: 500,
                color: '#3F2F5B',
                mb: 1
              }}>
                Meal Type
              </Typography>
              <Typography sx={{ color: 'rgb(107, 114, 128)' }}>
                {[
                  selectedMealData.is_breakfast && 'Breakfast',
                  selectedMealData.is_lunch && 'Lunch',
                  selectedMealData.is_dinner && 'Dinner'
                ].filter(Boolean).join(', ')}
              </Typography>
            </Box>
          </>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => setIsModalOpen(false)}
            sx={{
              color: 'rgb(242, 80, 64)',
              border: '1px solid rgb(242, 80, 64)',
              borderRadius: '20px',
              px: 4,
              py: 1,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(242, 80, 64, 0.04)'
              }
            }}
          >
            Close
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MealCalendar;