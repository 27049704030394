import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Pagination,
  Paper,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { TENANT_URL } from "../../../config";

// Custom Styled Components
const CustomPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '10px',
  boxShadow: '0px 14px 42px 0px #080F340F',
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
  },
  '& .Mui-selected': {
    backgroundColor: '#F0645B !important', // Active page background color
    color: '#FFFFFF', // Active page text color
    fontWeight: 'bold', // Optional: Make it bold
  },
}));

const StickyTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '500px', // Set max height for scrollability
  overflowY: 'auto', // Enable vertical scrolling
  height:'500px',
  '& thead th': {
    position: 'sticky', // Sticky header
    top: 0,
    zIndex: 2, // Ensure it stays above the rows
    backgroundColor: theme.palette.background.paper, // Match background to avoid transparency
    boxShadow: '0px 2px 2px -1px rgba(0,0,0,0.2)', // Optional: subtle shadow for clarity
  },
}));

const getStatusChip = (status) => {
  const commonStyles = {
    fontSize: '12px',
    width: '105px',
    height: '28px',
    padding: '6px 5px',
    gap: '10px',
    borderRadius: '14px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  switch (status) {
    case 'Delivered':
      return (
        <Chip
          label="Delivered"
          style={{
            ...commonStyles,
            backgroundColor: '#D9FFD9',
            color: '#007F00',
          }}
        />
      );
    case 'Pending':
      return (
        <Chip
          label="Pending"
          style={{
            ...commonStyles,
            backgroundColor: '#FFE0E0',
            color: '#D30000',
          }}
        />
      );
    case 'In Progress':
      return (
        <Chip
          label="In Progress"
          style={{
            ...commonStyles,
            backgroundColor: '#FFF0D4',
            color: '#E49A08',
          }}
        />
      );
    default:
      return null;
  }
};

const MIN_ROWS = 10;

const OrderHistory = () => {
  const { searchVal } = useOutletContext();
  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState({})
  const totalPages = Math.ceil(currPage?.total / currPage?.per_page);
  console.log('!!!!!currPage', currPage)

  const handlePagination = (event,page) =>{
    fetchDataSubscription(`/customer/app/order/calender?page=${page}`)

  }

  const fetchDataSubscription = async (url) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("resssss###",response.data)
      setData(response.data.data || []); // Set empty array if no data
      setCurrPage(response)
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    fetchDataSubscription('/customer/app/order/calender');
  }, []);

  // Ensure at least 10 rows are displayed
  const rowsToRender = [...data];

  useEffect(() => {
    console.log("helooooo")
    const getData = setTimeout(async() => {
      const url = `/customer/app/order/calender?search=${searchVal}`
      const endpoint = `${TENANT_URL}${url}`;
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(response.data.data || []); // Set empty array if no data
      setCurrPage(response)
    } catch (error) {
      console.error('Error fetching while in search ', error);
    }

    }, 2000)

    return () => clearTimeout(getData)
  }, [searchVal])

  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
        // height: '120vh',
      }}
    >
      <CustomPaper>
        <Box>
          <StickyTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="500" fontSize={13}>
                      Sr.#
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="500" fontSize={13} textAlign={'center'}>
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="500" fontSize={13} textAlign={'center'}>
                      Order Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="500" fontSize={13} textAlign={'center'}>
                      Status
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
  {rowsToRender.length > 0 ? (
    rowsToRender.map((row, index) => (
      <TableRow key={row.id}>
        <TableCell
          sx={{
            fontSize: '13px',
            fontWeight: '400',
            textAlign: 'start',
          }}
        >
          {index + 1}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '13px',
            fontWeight: '400',
            textAlign: 'center',
          }}
        >
          {row.type}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '13px',
            fontWeight: '400',
            textAlign: 'center',
          }}
        >
          {row.date}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '13px',
            fontWeight: '400',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {row.status ? getStatusChip(row.status) : '-'}
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={4} align="center" sx={{border:'none', height:'440px'}}>
        <Typography variant="body1" color="textSecondary">
          No data found
        </Typography>
      </TableCell>
    </TableRow>
  )}
</TableBody>
            </Table>
          </StickyTableContainer>
        </Box>
      </CustomPaper>
      <Box display="flex" marginTop="16px" padding="16px">
        <CustomPagination count={totalPages || 0} variant="outlined" shape="rounded" sx={{justifyContent:'end'}} onChange={handlePagination}/>
      </Box>
    </Box>
  );
};

export default OrderHistory;
