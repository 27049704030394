import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useProfile } from '../../../customHooks/useProfile';
import { ReactComponent as NotesIcon } from '../assets/notes.svg';
import Cross from '../assets/cross.svg';
import { TENANT_URL } from '../../../../config';
import axios from 'axios';
import {
  Box,
  MenuItem,
  Select,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Button,
  Modal,
} from '@mui/material';
import FormTextarea from '../../../../admin/pages/trail/components/formfied/texreafiels';

const NoteCard = ({}) => {
  const { notesHistory, loading, currentCustomer } = useProfile();
  const [ note, setNote ] = useState();
  const [errorMessage , setErrorMessage] = useState("");
  


  const resetNotes = () => {
    setNote("");
  }

  const saveNotes = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    if (!note) {
      setErrorMessage('Please enter some notes')
      return;
    }
  
    const addConfig = {
      method: 'post',
      url: `${TENANT_URL}/customer/notes`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  
    const data = {
      customer_id: currentCustomer,
      note: note,
    };
  
    try {
      const response = await axios({ ...addConfig, data });
      console.log("response:",response);
      console.log(response.status)
      if (response.status == "201") {
        console.log("asdas")
        setNote("")
        setOpenModal(false)
      } else {
        setErrorMessage(response.data.message || 'Some error occur. Please try again.')
      }
    } catch (error) {
      console.error(error);
    }

  }
  

  const theme = useTheme();
  const [secondSelected, setSecondSelected] = useState('1');


  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          <Box
            sx={{
              // display:'none',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              width: '100%',
              mb: 0,
              boxSizing: 'border-box',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                marginBottom: '6px',
                textTransform: 'uppercase',
              }}
            >
              Delivery type
            </Typography>
            <Select
              value={secondSelected}
              onChange={(event) => setSecondSelected(event.target.value)}
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Outfit',
                background:
                  'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
                height: '45px',
                border: '1px solid #B0B0B0',
                lineHeight: '20.16px',
                marginBottom: '17px',
                borderRadius: '10px',
                width: '100%',
                backdropFilter: 'blur(20px)',
                '& .MuiSelect-icon': {
                  top: 'calc(50% - 12px)',
                  right: '10px',
                },
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
              }}
              IconComponent={ExpandMoreIcon}
            >
              <MenuItem value="1">Pickup</MenuItem>
              <MenuItem value="2">Drop</MenuItem>
            </Select>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                marginBottom: '6px',
                textTransform: 'uppercase',
              }}
            >
              Notes
            </Typography>
            <Box
              sx={{
                maxHeight: '500px', // You can adjust this value to suit your layout
                overflowY: 'auto', // Enables scrolling when content overflows
              }}
            >
              <List
                sx={{
                  maxHeight: 'initial', // You can adjust this value to suit
                }}
              >
                {notesHistory.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      display: 'flex',
                      background:
                        'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
                      borderRadius: '15px',
                      padding: '10px 10px',
                      boxShadow: '0px 4px 4px 0px #00000026',
                      marginBottom: '11px',
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: '32px',
                      }}
                    >
                      <NotesIcon />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: '13px',
                        fontWeight: '400',
                        marginBottom: '6px',
                        color: '#3F2F5B',
                      }}
                    >
                      {item.note}
                    </Typography>

                    <Box sx={{ minWidth: '70px' }}>
                      <Typography
                        sx={{
                          fontSize: '10px',
                          fontWeight: '400',
                          marginBottom: '6px',
                          textAlign: 'right',
                          color: '#3F2F5B',
                          fontStyle: 'italic',
                        }}
                      >
                        {item.created_at}
                      </Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
              }}
            >
              <Button onClick={handleOpenModal} variant="contained">
                Add New
              </Button>
            </Box>
          </Box>
        </>
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '600px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Add Note
            </Typography>
            <img src={Cross} alt="Close" onClick={handleCloseModal} />
          </div>

          <textarea
            placeholder="Type here..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
            style={{
              width: '100%',
              height: '150px',
              borderRadius: '8px',
              padding: '10px',
              border: '1px solid #ccc',
              fontSize: '16px',
              fontFamily: 'Arial, sans-serif',
              resize: 'vertical', // Allows resizing only vertically
            }}
            aria-label="Note input"
          />

          {errorMessage}
          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Button
              onClick={resetNotes}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#F0645B',
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
              }}
              onClick={saveNotes}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default NoteCard;
