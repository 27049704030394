import React from 'react';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Button, Typography } from '@mui/material';
import SliderImg from '../../assets/images/slider.png';
import CircleImg from '../../assets/images/circle.png';
export default function TrialComponent() {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      pagination={{
        clickable: true,
      }}
    >
      <SwiperSlide>
        <div className="row align-items-center position-relative">
          <div className="col-md-5">
            <Typography
              variant="h4"
              lineHeight={'40px'}
              sx={{
                fontSize: '32px',
                fontWeight: 700,
                letterSpacing: '2%',
                color: '#ffffff',
                mb: 2,
                fontFamily: 'Outfit !important',
              }}
            >
              Enjoy Free Meals On An Exclusive Trial
            </Typography>
            <Typography
              variant="h4"
              lineHeight={'28px'}
              sx={{
                fontSize: '17px',
                fontWeight: 500,
                color: '#ffffff',
                mb: 5,
                fontFamily: 'Outfit !important',
              }}
            >
              Lorem ipsum dolor sit amet consectetur. Viverra amet sed est dis.
              Malesuada consectetur est dui tempor diam id.
            </Typography>

            <Button
              variant="contained"
              sx={{
                backgroundColor: '#FACD2D',
                borderRadius: 100,
                textTransform: 'capitalize',
                boxShadow: 'none',
                color: '#000000',
                height: 'auto',
                padding: '13px 26px',
                fontFamily: 'Outfit !important',
                '&:hover': {
                  backgroundColor: '#FACD2D',
                  boxShadow: 'none',
                  opacity: 0.5,
                },
              }}
            >
              Register Now
            </Button>
          </div>
          <div className="col-md-6 text-center">
            <img
              src={SliderImg}
              style={{
                width: '450px',
                resize: 'contain',
                height: '450px',
              }}
            />
          </div>
          <img
            src={CircleImg}
            style={{
              width: '60px',
              resize: 'contain',
              height: '60px',
              position: 'absolute',
              right: '15px',
              top: 0,
            }}
          />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="row align-items-center position-relative">
          <div className="col-md-5">
            <Typography
              variant="h4"
              lineHeight={'40px'}
              sx={{
                fontSize: '32px',
                fontWeight: 700,
                letterSpacing: '2%',
                color: '#ffffff',
                mb: 2,
                fontFamily: 'Outfit !important',
              }}
            >
              Enjoy Free Meals On An Exclusive Trial
            </Typography>
            <Typography
              variant="h4"
              lineHeight={'28px'}
              sx={{
                fontSize: '17px',
                fontWeight: 500,
                color: '#ffffff',
                mb: 5,
                fontFamily: 'Outfit !important',
              }}
            >
              Lorem ipsum dolor sit amet consectetur. Viverra amet sed est dis.
              Malesuada consectetur est dui tempor diam id.
            </Typography>

            <Button
              variant="contained"
              sx={{
                backgroundColor: '#FACD2D',
                borderRadius: 100,
                textTransform: 'capitalize',
                boxShadow: 'none',
                color: '#000000',
                height: 'auto',
                fontFamily: 'Outfit !important',
                padding: '13px 26px',
                '&:hover': {
                  backgroundColor: '#FACD2D',
                  boxShadow: 'none',
                  opacity: 0.5,
                },
              }}
            >
              Register Now
            </Button>
          </div>
          <div className="col-md-6 text-center">
            <img
              src={SliderImg}
              style={{
                width: '450px',
                resize: 'contain',
                height: '450px',
              }}
            />
          </div>
          <img
            src={CircleImg}
            style={{
              width: '60px',
              resize: 'contain',
              height: '60px',
              position: 'absolute',
              right: '15px',
              top: 0,
            }}
          />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="row align-items-center position-relative">
          <div className="col-md-5">
            <Typography
              variant="h4"
              lineHeight={'40px'}
              sx={{
                fontSize: '32px',
                fontWeight: 700,
                letterSpacing: '2%',
                color: '#ffffff',
                mb: 2,
                fontFamily: 'Outfit !important',
              }}
            >
              Enjoy Free Meals On An Exclusive Trial
            </Typography>
            <Typography
              variant="h4"
              lineHeight={'28px'}
              sx={{
                fontSize: '17px',
                fontWeight: 500,
                color: '#ffffff',
                mb: 5,
                fontFamily: 'Outfit !important',
              }}
            >
              Lorem ipsum dolor sit amet consectetur. Viverra amet sed est dis.
              Malesuada consectetur est dui tempor diam id.
            </Typography>

            <Button
              variant="contained"
              sx={{
                backgroundColor: '#FACD2D',
                borderRadius: 100,
                textTransform: 'capitalize',
                boxShadow: 'none',
                color: '#000000',
                fontFamily: 'Outfit !important',
                height: 'auto',
                padding: '13px 26px',
                '&:hover': {
                  backgroundColor: '#FACD2D',
                  boxShadow: 'none',
                  opacity: 0.5,
                },
              }}
            >
              Register Now
            </Button>
          </div>
          <div className="col-md-6 text-center">
            <img
              src={SliderImg}
              style={{
                width: '450px',
                resize: 'contain',
                height: '450px',
              }}
            />
          </div>
          <img
            src={CircleImg}
            style={{
              width: '60px',
              resize: 'contain',
              height: '60px',
              position: 'absolute',
              right: '15px',
              top: 0,
            }}
          />
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
