import React, { useState } from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { usePickupGroup } from '../../customHooks/usePickupGroup';

const PickupFilter = ({ isMdUp, width = '100%' }) => {
  const { selectedFilter, setSelectedFilter } = usePickupGroup();

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };
  const menuItemStyle = { textAlign: 'center', fontSize: '14px' };
  return (
    <FormControl sx={{ width: '100%' }}>
      <TextField
        sx={{
          background: 'transparent',
        }}
        select
        labelId="filter-by-label"
        id="filter-by-select"
        value={selectedFilter}
        onChange={handleChange}
        size="small"
        InputProps={{
          sx: {
            width: width,
            minWidth: '130px',
            height: '40px',
            background: 'transparent',
            borderRadius: '30px',
            fontSize: '14px',
            '& fieldset': {
              borderRadius: '30px',
            },
            '&:hover': {
              '& fieldset': {
                border: '1px solid #3F2F5B',
                borderColor: '#3F2F5B !important',
              },
            },
            '&.Mui-focused': {
              '& fieldset': {
                borderColor: '#FF7676 !important',
              },
            },
          },
        }}
      >
        <MenuItem sx={menuItemStyle} value="all">
          Filter by
        </MenuItem>
        <MenuItem sx={menuItemStyle} value="is_active=true">
          Filter by 1
        </MenuItem>
        <MenuItem sx={menuItemStyle} value="payment_due=true">
          Filter by 2
        </MenuItem>
      </TextField>
    </FormControl>
  );
};

export default PickupFilter;
