import React from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const CustomBox = ({ value, selected, onClick }) => (
  <Box
    sx={{
      width: '33px',
      height: selected ? '36px' : '30px',
      display: 'flex',
      p: 1,
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: selected ? '#FF7676' : 'transparent',
      color: selected ? 'white' : '#FF7676',
      transition: 'all ease 0.3s',
      '&:hover': {
        color: '#000000',
      },
    }}
    onClick={onClick}
  >
    {value}
  </Box>
);

const Pagination = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography>Showing 1 to 10 of 20 entries</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CustomBox value={<KeyboardArrowLeftIcon />} />
        <CustomBox value={1} />
        <CustomBox value={2} selected={true} />
        <CustomBox value={3} />
        <CustomBox value={<KeyboardArrowRightIcon />} />
      </Box>
    </Box>
  );
};

export default Pagination;
